.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.uppercase {
  text-transform: uppercase;
  font-size: 59px;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: 0 0;
  font-weight: 400;
  font-style: normal;
  font-family: gordita_regular;
  text-decoration: none;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
input[type="button"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
ol,
ul {
  list-style: none;
}
blockquote {
  quotes: none;
}
blockquote:after,
blockquote:before {
  content: "";
  content: none;
}
del {
  text-decoration: line-through;
}
address,
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
body {
  font-family: gordita_regular;
  font-size: 14px;
  color: #000;
  overflow-x: hidden;
  min-width: 320px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}
input,
select,
textarea {
  font-family: product_sansregular;
}
html {
  overflow: scroll !important;
  scroll-behavior: smooth !important;
}
html.modal-enabled {
  overflow: hidden !important;
}
a {
  color: #444;
}
.submit:hover,
a:hover {
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}
.thumb {
  display: block;
}
.thumb img {
  display: block;
  width: 100%;
}
p {
  line-height: 1.6em;
  font-size: 16px;
  color: #868686;
  font-family: gordita_regular;
}
.hidden {
  display: none;
}
br.clear {
  clear: both;
  line-height: 0;
}
.bold {
  font-family: product_sansbold;
}
.e-t-h {
  margin-bottom: 25px;
}
.e-t-h2 {
  font-size: 37px;
  color: #444;
  font-family: product_sansbold;
  letter-spacing: 0.02rem;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 1325px;
}
.b-regular {
  font-family: baloo_paaji_2regular;
}
.b-medium {
  font-family: gordita_medium;
}
.b-semi {
  font-family: baloo_paaji_2semibold;
}
.white {
  color: #fff;
}
.black {
  color: #000;
}
a,
h1,
h2,
input {
  font-family: "goridita_regular";
  color: #000;
}
input::-webkit-input-placeholder {
  color: #9b9a9a;
}
input:-moz-placeholder {
  color: #9b9a9a;
}
@font-face {
  font-family: "Indulekha_regular";
  src: url(../fonts/indhulekha/fmlin0ntt-webfont.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Indulekha_heavy";
  src: url(../fonts/indhulekha/indulekha_heavy.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EGGIndulekhaUni";
  src: url(../fonts/indhulekha/EGGIndulekhaUniB.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IndulekhaBold";
  src: url(../fonts/indhulekha/indulekhaHeavyBold.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: auto;
  font-family: product_sansregular;
  src: url(../fonts/productsans/productsans-regular-webfont.woff2)
      format("woff2"),
    url(../fonts/productsans/productsans-regular-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: auto;
  font-family: product_sansbold;
  src: url(../fonts/productsans/productsans-bold-webfont.woff2) format("woff2"),
    url(../fonts/productsans/productsans-bold-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: baloo_paaji_2semibold;
  src: url(../fonts/baloo-paaji/baloopaaji2-semibold-webfont.woff2)
      format("woff2"),
    url(../fonts/baloo-paaji/baloopaaji2-semibold-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: baloo_paaji_2regular;
  src: url(../fonts/baloo-paaji/baloopaaji2-regular-webfont.woff2)
      format("woff2"),
    url(../fonts/baloo-paaji/baloopaaji2-regular-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: baloo_paaji_2medium;
  src: url(../fonts/baloo-paaji/baloopaaji2-medium-webfont.woff2)
      format("woff2"),
    url(../fonts/baloo-paaji/baloopaaji2-medium-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: baloo_paaji_2bold;
  src: url(../fonts/baloo-paaji/baloopaaji2-bold-webfont.woff2) format("woff2"),
    url(../fonts/baloo-paaji/baloopaaji2-bold-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: century_gothicregular;
  src: url(../fonts/century-gothic/gothic-webfont.woff2) format("woff2"),
    url(../fonts/century-gothic/gothic-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: century_gothicbold;
  src: url(../fonts/century-gothic/gothicb-webfont.woff2) format("woff2"),
    url(../fonts/century-gothic/gothicb-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: carlitobold;
  src: url(../fonts/carlito/carlito-bold-webfont.woff2) format("woff2"),
    url(../fonts/carlito/carlito-bold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: carlitoregular;
  src: url(../fonts/carlito/carlito-regular-webfont.woff2) format("woff2"),
    url(../fonts/carlito/carlito-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "gordita_regular";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Regular.woff) format("woff");
}

@font-face {
  font-family: "gordita_regular_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Regular_Italic.woff)
    format("woff");
}

@font-face {
  font-family: "gordita_thin";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Thin.woff) format("woff");
}

@font-face {
  font-family: "gordita_thin_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Thin_Italic.woff) format("woff");
}

@font-face {
  font-family: "gordita_light";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Light.woff) format("woff");
}

@font-face {
  font-family: "Gordita_light_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Light_Italic.woff) format("woff");
}

@font-face {
  font-family: "gordita_medium";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Medium.woff) format("woff");
}

@font-face {
  font-family: "gordita_medium_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Medium_Italic.woff)
    format("woff");
}

@font-face {
  font-family: "gordita_bold";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Bold.woff) format("woff");
}

@font-face {
  font-family: "gordita_bold_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Bold_Italic.woff) format("woff");
}

@font-face {
  font-family: "gordita_black";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Black.woff) format("woff");
}

@font-face {
  font-family: "gordita_black_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Black_Italic.woff) format("woff");
}

@font-face {
  font-family: "gordita_ultra";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Ultra.woff) format("woff");
}

@font-face {
  font-family: "gordita_ultra_italic";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/gordita-cufonfonts/Gordita_Ultra_Italic.woff) format("woff");
} */

@font-face {
  font-family: gordita_regular;
  src: url("../fonts/figtree/figtree-regular.woff2") format("woff2"),
    url("../fonts/figtree/figtree-regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: gordita_medium;
  src: url("../fonts/figtree/figtree-semibold.woff2") format("woff2"),
    url("../fonts/figtree/figtree-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: gordita_bold;
  src: url("../fonts/figtree/figtree-black.woff2") format("woff2"),
    url("../fonts/figtree/figtree-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

#spotlight {
  min-height: 100vh;
  color: #fff !important;
  position: relative;
  background: linear-gradient(#667eea, #764ba2);
  display: flex;
  flex-direction: column;
}
#spotlight .animate-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255);
  opacity: 0.4;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes MissionlayerAnimate {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
#spotlight header {
  flex: 0 0 auto;
}
#spotlight header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
}
.web-menu-icon {
  height: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
}
.web-menu-icon span.line {
  height: 2px;
  width: 26px;
  background: #fff;
  display: block;
}
.web-menu-icon span.line:nth-child(2) {
  width: 14px;
}
.web-menu-icon span.line:last-child {
  width: 20px;
}
#spotlight header h1.logo {
  width: 7.7%;
}
#spotlight header a.learn {
  font-family: product_sansbold;
  color: #fff;
  font-size: 19px;
}
#spotlight div.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 32px 0;
  flex: 1;
}
#spotlight div.main::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: #9ba0e7;
  height: 1px;
  opacity: 0;
  width: 100%;
  animation-name: linedraw;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes linedraw {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#spotlight div.main h1 {
  letter-spacing: 0.02rem;
  font-size: 53px;
  color: #fff;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 6s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  text-align: center;
}
@keyframes anm {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-17px);
  }
}
#spotlight div.main h4 {
  font-size: 19px;
  margin: 30px 0 76px;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 6s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#spotlight div.main div.keys {
  display: flex;
  justify-content: space-between;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 6s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#spotlight div.main div.keys .round {
  height: 170px;
  width: 170px;
  display: block;
  border-radius: 50%;
  border: 1.5px solid #fff;
  margin-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: product_sansbold;
  font-size: 20px;
  color: #fff;
}
#spotlight div.main div.keys .round:last-child {
  margin-right: 0;
}
#spotlight div.main div.keys .bottom span.round {
  margin-right: 0;
}
#spotlight div.main .button {
  display: inline-block;
  padding: 23px 82px;
  border-radius: 35px;
  background: #fff;
  color: #744ba2;
  text-transform: uppercase;
  font-family: product_sansbold;
  font-size: 18px;
  margin-top: 69px;
  cursor: pointer;
}
#web_tech_schooling {
  padding: 80px 0;
}
#web_tech_schooling section.wrapper {
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/tech-schooling/tech_schoolingbg.png");
  background-size: cover;
}
#web_tech_schooling div.top_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#web_tech_schooling div.top_section div.left_section {
  width: 40%;
}
#web_tech_schooling div.top_section div.left_section img {
  width: 100%;
  display: block;
}
#web_tech_schooling div.top_section div.right_section {
  width: 50%;
  transform: translateY(-64px);
}
#web_tech_schooling div.top_section div.right_section h2 {
  font-size: 57px;
  margin-bottom: 30px;
}
#web_tech_schooling div.top_section div.right_section p {
  font-size: 16px;
  margin-bottom: 30px;
}
#web_tech_schooling div.top_section div.right_section a {
  font-size: 18px;
  display: inline-block;
  padding: 20px 65px;
  background: linear-gradient(to bottom, #6b6dd1 0, #6d66c9 100%);
  color: #fff;
  border-radius: 30px;
}
#web_tech_schooling div.bottom_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -55px;
}
#web_tech_schooling div.bottom_section div.cards {
  width: 32%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  border-radius: 15px;
  box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
    0 12px 90px 0 rgba(103, 151, 255, 0.11);
}
#web_tech_schooling div.bottom_section div.cards div.img_container {
  width: 20%;
}
#web_tech_schooling div.bottom_section div.cards div.img_container img {
  width: 100%;
  display: block;
}
#web_tech_schooling div.bottom_section div.cards div.content_container {
  width: 72%;
}
#web_tech_schooling div.bottom_section div.cards div.content_container h3 {
  font-size: 21px;
  line-height: 25px;
}
#web_tech_schooling div.bottom_section div.cards div.content_container p {
  font-size: 18px;
  color: #525252;
}
#about {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/about.png")
    no-repeat;
  background-position: right -494px;
  background-size: contain;
  background-repeat: no-repeat;
}
#about .bg-animate {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/about.png")
    no-repeat;
  background-position: right -494px;
  background-size: contain;
  background-repeat: no-repeat;
}
#about .animate-layer-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
}
#about.animate .animate-layer-container {
  animation-name: gradiantMove;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#about .animate-layer {
  background: linear-gradient(180deg, #667eea 0, rgba(255, 255, 255, 0) 93%);
  height: 100%;
  position: relative;
  top: 0;
}
#about.animate .animate-layer {
  animation-name: gradiantStress;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes gradiantMove {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
@keyframes gradiantStress {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
#about .top {
  width: 46%;
}
#about .top h2 {
  font-size: 43px;
  letter-spacing: 0.02rem;
  line-height: 3.5rem;
}
#about .top p {
  font-size: 18px;
  margin: 40px 0;
}
#about .items {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
}
#about .items .item {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#about .items .item .icon {
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#about .items .item .icon img {
  width: 65%;
  display: block;
}
#about .items .item h3 {
  letter-spacing: 0.03rem;
  font-size: 28px;
  line-height: 2.5rem;
  margin: 40px 0 35px;
}
#about .items .item p {
  font-size: 18px;
}
#about .items .item .button {
  display: block;
  background: #000;
}
#about .items .item a {
  display: inline-block;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#3cde74, #14abed);
  font-family: product_sansbold;
  font-size: 16px;
  margin-top: 40px;
}
#about .items .item:first-child a {
  background: -webkit-linear-gradient(#3cde74, #14abed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#about .items .item:nth-child(2) a {
  background: -webkit-linear-gradient(#37cdfc, #4069fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#about .items .item:nth-child(3) a {
  background: -webkit-linear-gradient(#f25d74, #f78f4a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#initiative {
  position: relative;
  background: linear-gradient(#5e70bd, #9966e6);
}
#initiative.common-animate .anm-top {
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes firstanimate {
  0% {
    left: -130%;
  }
  100% {
    left: 0;
  }
}
#initiative.common-animate .anm-bottom {
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes secondanimate {
  0% {
    right: -130%;
  }
  100% {
    right: 0;
  }
}
.common-section {
  min-height: 100vh;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.common-section .head {
  text-align: center;
}
.common-section.common-animate .head {
  transform: translateY(-25px);
  animation-name: upanimate;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes upanimate {
  0% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}
.common-section .head span.top {
  display: inline-block;
  text-transform: uppercase;
  font-size: 42px;
  letter-spacing: 0.05rem;
}
.common-section .head h2 {
  font-size: 70px;
  letter-spacing: 0.05rem;
  line-height: 6rem;
  color: #fff;
  margin: 52px 0 186px;
}
.common-section .head .button {
  cursor: pointer;
  background: #fff;
  padding: 28px 117px 28px 58px;
  display: inline-block;
  border-radius: 45px;
  position: relative;
  animation-name: updown;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes updown {
  0% {
    transform: translateY(-14px);
  }
  100% {
    transform: translateY(5px);
  }
}
.common-section .head .button .text {
  color: #444;
  font-family: product_sansbold;
  font-size: 26px;
  letter-spacing: 0.04rem;
}
.common-section .head .button .round {
  display: block;
  height: 90px;
  width: 90px;
  top: -4px;
  right: -4px;
  position: absolute;
  border-radius: 50%;
  background: #a068ea;
  -webkit-box-shadow: 0 1px 19px -4px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 1px 19px -4px rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 19px -4px rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-section .head .button .round i {
  color: #fff;
  font-size: 33px;
  -webkit-text-stroke-width: 2px;
}
#techiespark {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.grad-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #e5e9fe 0, rgba(255, 255, 255, 0) 93%);
}
#techiespark.animate .grad-layer {
  animation-name: gradiant-move;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes gradiant-move {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
#techiespark .wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#techiespark .left {
  width: 66%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#techiespark .left .column {
  display: flex;
  flex-direction: column;
  width: 43%;
}
#techiespark .left img {
  display: block;
  width: 100%;
}
#techiespark.animate .left img {
  animation-name: zoom-animate;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes zoom-animate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#techiespark.animate .left .img .slide {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 10;
  transform: translateX(0);
  animation-name: animatethis;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes animatethis {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(100%);
  }
}
#techiespark .left .img {
  overflow: hidden;
  position: relative;
}
#techiespark .left .column:first-child {
  align-items: flex-end;
  margin-right: 19px;
}
#techiespark .left .column:first-child .img:nth-child(1),
#techiespark .left .column:nth-child(2) .img:nth-child(1) {
  margin-bottom: 19px;
}
#techiespark .left .column:first-child .img:nth-child(1) {
  width: 86%;
}
#techiespark .left .column:first-child .img:nth-child(2) {
  width: 45%;
}
#techiespark .left .column:nth-child(2) .img:nth-child(1) {
  width: 79%;
}
#techiespark .left .column:nth-child(2) .img:nth-child(2) {
  width: 106%;
}
#techiespark .right {
  width: 30.4%;
  transform: translateY(101px);
}
#techiespark .right h2 {
  font-size: 45px;
  letter-spacing: 0.05rem;
}
#techiespark .right .bottom {
  letter-spacing: 0.06rem;
  font-size: 22px;
  display: block;
  margin: 15px 0 50px;
}
#techiespark .right p {
  font-size: 18px;
}
#techiespark .right .button {
  display: inline-block;
  text-transform: uppercase;
  font-family: product_sansbold;
  color: #4caf50;
  font-size: 18px;
  margin-top: 59px;
  position: relative;
  letter-spacing: 0.01rem;
  transition: 0.5s;
}
#techiespark .right .button::after {
  bottom: -6px;
  left: 0;
  position: absolute;
  content: "";
  width: 60%;
  height: 1px;
  background: #4caf50;
  transition: ease-in-out 0.5s;
}
#techiespark .right .button:hover::after {
  width: 100%;
}
#why-industry.common-section {
  position: relative;
}
#why-industry.common-section .grad-layer {
  top: 0;
}
#why-industry.common-section.common-animate .grad-layer {
  animation-name: color-move;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes color-move {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
#support.common-section,
#why-industry.common-section {
  background: #fff;
}
#support.common-section .grad-layer {
  top: 0;
}
#support.common-section {
  position: relative;
}
#support.common-section.common-animate .grad-layer {
  animation-name: color-move;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#support.common-section .head h2,
#support.common-section .head span.top,
#why-industry.common-section .head h2,
#why-industry.common-section .head span.top {
  color: #444;
}
#support.common-section .head .button,
#why-industry.common-section .head .button {
  padding: unset;
}
#support.common-section .head .button .round,
#why-industry.common-section .head .button .round {
  position: unset;
  background: #444;
}
#industry {
  min-height: 100vh;
  background: #343434;
  display: flex;
  align-items: center;
  position: relative;
}
#industry.slide-animate::after {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes slideanm {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
#industry .wrapper {
  position: relative;
}
#industry .head {
  text-align: center;
  margin-bottom: 65px;
}
#industry .head h3 {
  color: #fff;
  font-size: 43px;
  letter-spacing: 0.05rem;
}
#industry.slide-animate .head h3 {
  animation-name: coloranm;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#industry .head .bottom {
  color: #fff;
  display: inline-block;
  letter-spacing: 0.03rem;
  font-size: 21px;
  margin-top: 17px;
  display: inline-block;
}
#industry.slide-animate .head .bottom {
  animation-name: coloranm;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#industry .contents {
  margin: 0 auto;
}
#industry .contents .content-box:first-child {
  margin-bottom: 90px;
}
#industry .contents .content-box .items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#industry .contents .content-box .items .item {
  width: 46%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
#industry .contents .content-box .items .item:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
#industry .contents .content-box .items .item .line {
  width: 50px;
  margin-right: 55px;
  height: 1px;
  background: #fff;
  display: inline-block;
}
#industry.slide-animate .contents .content-box .items .item .line {
  animation-name: bganm;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#industry .contents .content-box h5 {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 50px;
  letter-spacing: 0.04rem;
}
#industry.slide-animate .contents .content-box h5 {
  animation-name: coloranm;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#industry .contents .content-box h4 {
  color: #fff;
  font-size: 21px;
  width: fit-content;
}
#industry.slide-animate .contents .content-box h4 {
  animation-name: coloranm;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes coloranm {
  0% {
    color: #343434;
  }
  100% {
    color: #fff;
  }
}
@keyframes bganm {
  0% {
    background: #343434;
  }
  100% {
    background: #fff;
  }
}
#industry .button {
  cursor: pointer;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 1px 19px -4px rgba(0, 0, 0, 0.6);
  bottom: 0;
  right: 0;
  animation-name: updown;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 1.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#industry .button i {
  color: #343434;
  font-size: 33px;
  -webkit-text-stroke-width: 2px;
  animation-name: arrow;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 1.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
#missions {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(#8ee163, #65e3a5);
  position: relative;
}
#missions.mission-animate::after {
  animation-name: missionslide;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes missionslide {
  0% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}
#missions .animate-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255);
  opacity: 0.4;
  animation-name: layerAnimate;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes layerAnimate {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}
#missions .wrapper {
  max-width: 1509px;
}
#missions .items {
  display: flex;
  justify-content: center;
}
#missions .items .item {
  padding: 80px 58px 55px;
  width: 32%;
  text-align: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#missions .items .item .img-container {
  margin-bottom: 27px;
}
#missions .items .item img {
  display: block;
  width: 69%;
  margin: 0 auto 20px;
}
#missions .items .item:first-child img {
  width: 62%;
}
#missions .items .item p {
  font-size: 18px;
  margin-bottom: 25px;
}
#missions .items .item .button {
  width: 49px;
  height: 49px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  margin: auto auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#missions .items .item .button .icon {
  color: #fff;
  font-size: 24px;
  -webkit-text-stroke-width: 1px;
}
footer .box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 75px 0 0;
  background-size: cover;
}
footer .box img {
  width: 100%;
  display: block;
}
footer .box .address {
  display: inline-block;
  color: #4c4c4c;
  margin: 25px 0;
  letter-spacing: 0.01rem;
  text-align: center;
  line-height: 1.8rem;
}
footer .box .social {
  display: flex;
}
footer .box .social .item {
  width: 60px;
  height: 60px;
  background: linear-gradient(#8ee163, #65e3a5);
  border-radius: 23px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .box .social .item:last-child {
  margin-right: 0;
}
footer .box .social .item svg {
  width: 45%;
}
footer .box .bottom {
  color: #4c4c4c;
  /* width: 296px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  padding: 3px 0;
}
footer .box .bottom a {
  font-size: 17px;
  color: #4c4c4c;
  padding: 0 10px;
}
footer .box .bottom a:not(:last-child) {
  border-right: 1px solid #c5c5c5;
}
@media (max-width: 454px) {
  footer .box .bottom a {
    border-right: unset !important;
  }
}
@media (max-width: 480px) {
  footer .box {
    padding: 55px 0 0;
  }
}

#menu {
  height: 100vh;
  width: 100%;
  background: #3b3b3b;
  position: absolute;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
#menu .close-icon {
  position: absolute;
  top: 82px;
  left: 254px;
  cursor: pointer;
  transform: rotate(41deg);
  animation: icon-rotate 0.4s ease-in 0.5s 1 alternate forwards;
}
@keyframes icon-rotate {
  0% {
    transform: rotate(41deg);
  }
  100% {
    transform: rotate(0);
  }
}
#menu .close-icon .icon {
  color: #fff;
  font-size: 30px;
}
#menu .container .item {
  color: #fff;
  margin-bottom: 42px;
  position: relative;
}
#menu .container .item::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 0;
  background: #eceaea;
  bottom: -10px;
  left: 0;
}
#menu .container .item.active-menu::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 0;
  background: #eceaea;
  bottom: -10px;
  left: 0;
  animation: animate-line 0.4s ease-in 0s 1 alternate forwards;
}
#menu .container .item:hover::after {
  animation: animate-line 0.4s ease-in 0s 1 alternate forwards;
}
@keyframes animate-line {
  0% {
    width: 0;
  }
  100% {
    width: 297px;
  }
}
#menu .container .item:last-child {
  margin-bottom: 0;
}
#menu .container .item .number {
  font-size: 26px;
  margin-right: 76px;
}
#menu .container .item .menu {
  text-transform: uppercase;
  font-size: 27px;
}
#privacy-policy header {
  padding-top: 50px;
}
#privacy-policy header h1 {
  width: 100px;
  margin: 0 auto;
}
#techiespark-spotlight {
  background: linear-gradient(#93e160, #67e397);
  height: 780px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 60%);
}
#techiespark-spotlight header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 83px;
}
#techiespark-spotlight header .learn {
  font-family: product_sansbold;
  color: #fff;
  font-size: 19px;
}
#techiespark-spotlight .tp-main {
  text-align: center;
  margin-top: 234px;
}
#techiespark-spotlight .tp-main h1 {
  letter-spacing: 0.02rem;
  font-size: 58px;
  color: #fff;
}
#techiespark-spotlight .tp-main p {
  font-size: 17px;
  margin: 30px auto 0;
  color: #fff;
  width: 53%;
}
#how-works {
  padding: 90px 0;
}
#how-works .title {
  letter-spacing: 0.03rem;
  font-size: 35px;
  line-height: 2.5rem;
  margin-bottom: 56px;
  display: block;
}
#how-works .items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 43px;
}
#how-works .items .item {
  width: 26%;
  margin-bottom: 90px;
}
#how-works .items .item:nth-last-child(-n + 3) {
  margin-bottom: 0;
}
#how-works .items .item .icon {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#how-works .items .item .icon img {
  width: 69%;
  display: block;
}
#how-works .items .item h3 {
  letter-spacing: 0.03rem;
  font-size: 27px;
  line-height: 2.5rem;
  margin: 35px 0 28px;
}
#how-works .items .item p {
  font-size: 18px;
}
#innovation {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#innovation .head {
  text-align: center;
  margin: 0 auto 40px;
}
#innovation .head h2 {
  font-size: 45px;
  letter-spacing: 0.03rem;
  line-height: 3.5rem;
}
#innovation .video-container {
  display: flex;
  align-items: center;
  width: 85%;
  overflow: hidden;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
}
#innovation .video-container .overlay {
  background: #00000085;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#innovation .video-container .overlay img {
  width: 8%;
  display: block;
  cursor: pointer;
}
#innovation .video-container img {
  display: block;
  width: 100%;
}
#program.common-section .head span.top {
  color: #444;
}
#program.common-section .head h2 {
  color: #444;
}
#program.common-section .head .button .round {
  position: unset;
  background: #444;
}
.tp-com {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  align-items: center;
}
.tp-com h2 {
  font-size: 52px;
  letter-spacing: 0.02rem;
  color: #fff;
  text-align: center;
}
.tp-com .top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 0 90px;
}
.tp-com .top .round {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  margin-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: product_sansbold;
  font-size: 18.4px;
}
.tp-com .bottom .container {
  display: flex;
  justify-content: space-between;
  background: #f09998;
  padding: 0 95px;
}
.tp-com .bottom .container .box {
  width: 28%;
  padding: 76px 0;
  text-align: center;
}
.tp-com .bottom .container .box .icon {
  display: block;
}
.tp-com .bottom .container .box .icon img {
  width: 16%;
  display: block;
  margin: 0 auto 30px;
}
.tp-com .bottom .container .box p {
  font-size: 26px;
  color: #fff;
  letter-spacing: 0.02rem;
}
#program.common-section .head .button {
  padding: 0;
  width: fit-content;
}
#s-acce {
  background: linear-gradient(#ec6f9d, #ec8a6b);
}
#tech-edu {
  background: linear-gradient(#94e160, #64e3aa);
}
#tech-edu.tp-com .bottom .container {
  background: #68e8a2;
}
#tech-events {
  background: linear-gradient(#6dee9a, #24b0e7);
}
#tech-events.tp-com .bottom .container {
  background: #44cae0;
}
#e-tp {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#e-tp .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}
#e-tp .container .box {
  width: 50%;
}
#e-tp .container .box.left {
  background: #fff;
  padding-left: 81px;
  text-align: center;
}
#e-tp .container .box.left h2 {
  font-size: 48px;
}
#e-tp .container .box.left p {
  width: 70%;
  margin: 23px auto 0;
}
#e-tp .container .box.right {
  background: #f9f9f9;
  padding: 0 263px 0 120px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#e-tp .container .box.right .video-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 auto 63px;
  position: relative;
  width: 94%;
}
#e-tp .container .box.right .video-container:last-child {
  margin-bottom: 0;
}
#e-tp .container .box.right .video-container .overlay {
  background: #00000085;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#e-tp .container .box.right .video-container .overlay img {
  width: 13%;
  display: block;
  cursor: pointer;
}
#e-tp .container .box.right img {
  display: block;
  width: 100%;
}
#ed-tech #spotlight {
  background: linear-gradient(#6ded99, #14abed),
    url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/footer.png");
}
.i-c-s #spotlight:after {
  animation: unset;
}
.i-c-s #spotlight .main {
  height: calc(100vh - 191.59px);
  text-align: center;
}
.i-c-s #spotlight .main::before {
  display: none;
}
.i-c-s #spotlight .main .top {
  display: inline-block;
  background: #ffffff57;
  padding: 9px 12px;
  font-family: product_sansbold;
  font-size: 18px;
  border-radius: 5px;
}
.i-c-s #spotlight .main h1 {
  font-family: product_sansbold;
  margin: 30px 0 16px;
  line-height: 4.3rem;
  animation: unset;
}
.i-c-s #spotlight .main p {
  color: #fff;
  font-size: 19px;
  width: 37%;
  margin: 0 auto 38px;
}
.i-c-s #spotlight .main .bottom {
  display: flex;
  justify-content: center;
}
.i-c-s #spotlight .main .bottom .item {
  border: 1px solid #fff;
  padding: 14px 20px;
  width: 220px;
  font-size: 19px;
  border-radius: 5px;
  font-family: product_sansbold;
  margin-right: 32px;
  color: #fff;
  cursor: pointer;
}
.i-c-s #spotlight .main .bottom .item:last-child {
  margin-right: 0;
  background: #fff;
  color: #0ca9ef;
}
#ed-tech #e-t-about {
  padding: 80px 0;
}
#ed-tech #e-t-about .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#ed-tech #e-t-about .left {
  width: 48%;
}
#ed-tech #e-t-about .left p {
  font-size: 18px;
  line-height: 1.9rem;
}
#ed-tech #e-t-about .right img {
  display: block;
  width: 89%;
}
#ed-tech #why-ed-tech {
  text-align: center;
  padding: 80px 0;
  background-image: linear-gradient(
    90deg,
    rgba(242, 245, 250, 0) 0,
    #f2f5fa 100%
  ) !important;
}
#ed-tech #why-ed-tech .slick-dots {
  bottom: -46px !important;
}
#ed-tech #why-ed-tech .row {
  margin-top: 40px;
}
#ed-tech #why-ed-tech .row .item {
  background: linear-gradient(147deg, #e1f5dd 0, #ccf5e8 100%);
  color: #52af50;
  padding: 26px 30px;
  display: flex !important;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  justify-content: center;
}
#ed-tech #why-ed-tech .card {
  padding: 0 15px;
}
#ed-tech #why-ed-tech .slick-list {
  margin: 0 -15px !important;
}
#ed-tech #why-ed-tech .row .item .icon {
  font-size: 34px;
  margin-right: 12px;
}
#ed-tech #why-ed-tech .row .item h4 {
  font-size: 19px;
  font-family: product_sansbold;
  color: #52af50;
}
#ed-tech #expertise {
  text-align: center;
  padding: 100px 0;
}
#ed-tech #expertise .head {
  margin-bottom: 56px;
}
#ed-tech #expertise .items {
  display: flex;
  justify-content: space-between;
}
#ed-tech #expertise .items .item {
  text-align: center;
  width: 21.5%;
  box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
  padding: 40px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#ed-tech #expertise .items .item img {
  display: block;
  width: 47%;
  margin: 0 auto;
}
#ed-tech #expertise .items .item h5 {
  font-size: 18px;
  margin-top: 26px;
}
#learn {
  background: linear-gradient(#2c5676, #4d4375);
  text-align: center;
  padding: 100px 0 45px;
}
#learn .head {
  margin-bottom: 60px;
}
#learn .head h2 {
  color: #fff;
}
#learn .swiper-container {
  padding-bottom: 98px;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/path36.svg")
    no-repeat 54% 3%;
}
#learn .swiper-slide {
  background: #fff;
  padding: 82px 0;
  border-radius: 7px;
  border: 21px solid #e9e9e9;
  position: relative;
}
#learn .swiper-slide img {
  display: block;
  margin: 0 auto;
}
#learn .swiper-slide .designation {
  display: inline-block;
  font-family: product_sansbold;
  margin-top: 30px;
}
#learn .swiper-container .swiper-wrapper .swiper-slide {
  width: 300px;
  height: 399px;
  background: #fff;
  padding: 72px 0;
  border-radius: 7px;
  border: 21px solid #e9e9e9;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#learn
  .swiper-container
  .swiper-wrapper
  .swiper-slide.swiper-slide-active:after {
  display: none;
}
#learn .swiper-container .swiper-wrapper .swiper-slide:after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
}
#learn .swiper-container .swiper-wrapper .swiper-slide img {
  display: block;
  margin: 0 auto;
}
#learn .swiper-container .swiper-wrapper .swiper-slide .designation {
  display: inline-block;
  font-family: product_sansbold;
  margin-top: 30px;
  line-height: 1.7rem;
  width: 77%;
}
#learn .swiper-container .swiper-pagination {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
#learn .swiper-pagination-bullet.swiper-pagination-bullet-active {
  height: 20px !important;
  width: 20px !important;
  background: #fff !important;
}
#learn .swiper-pagination-bullet {
  height: 9px !important;
  width: 9px !important;
  background: #9a9a9a !important;
  margin: 0 12px !important;
}
#languages {
  padding: 100px 0;
  text-align: center;
  position: relative;
}
#languages .wrapper {
  position: relative;
}
#languages .head {
  margin-bottom: 70px;
}
#languages .head h2 {
  line-height: 3.4rem;
}
#languages .swiper-slide {
  height: 180px;
  padding: 26px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(#cc2b5e, #753a88);
}
#languages #arabic .top {
  font-size: 46px;
}
#languages .swiper-slide .top {
  font-size: 63px;
  font-weight: 700;
  color: #fff;
  display: block;
  margin-bottom: 20px;
}
#languages .swiper-slide .bottom {
  font-size: 35px;
  font-weight: 700;
  color: #fff;
  display: block;
}
#languages .slick-prev:before,
.slick-next:before {
  display: none;
}
#languages .slick-list {
  margin: 0 90px !important;
}
#languages .item {
  padding: 0 22px;
}
#languages .slick-prev {
  right: 0;
}
#languages .slick-prev {
  right: 10px;
}
#languages .slick-next {
  top: 74px;
}
#languages .swiper-next,
#languages .swiper-prev {
  cursor: pointer;
  top: 67%;
}
#languages .swiper-next {
  cursor: pointer;
  position: absolute;
  z-index: 23;
  left: 0;
}
#languages .swiper-prev {
  cursor: pointer;
  position: absolute;
  z-index: 23;
  right: 0;
}
#languages img {
  width: 55px;
  display: block;
}
#languages .swiper-next img {
  transform: rotate(180deg);
}
#et-working {
  padding: 100px 0;
  text-align: center;
}
#et-working .head {
  margin-bottom: 80px;
}
#et-working .items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#et-working .items .item {
  width: 22%;
  box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
  border-radius: 10px;
  padding: 50px 18px;
  margin: 35px 35px 0 0;
  background: #fff;
}
#et-working .items .item.item:nth-child(-n + 4) {
  margin-top: 0;
}
#et-working .items .item.item:nth-child(4),
#et-working .items .item.item:nth-child(7) {
  margin-right: 0;
}
#et-working .items .item .icon {
  height: 66px;
  width: 66px;
  background: #95cff2;
  border-radius: 20px;
  color: #fff;
  font-size: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
#et-working .items .item h5 {
  font-size: 19px;
  letter-spacing: 0.02rem;
  margin: 21px 0 18px;
}
#et-working .steps {
  margin-top: 100px;
}
#et-working .steps .tab {
  background: linear-gradient(to right, #67e7a8, #03a4f6);
  border-radius: 7px;
  color: #fff;
  margin-bottom: 21px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);
}
#et-working .steps .tab .top {
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
#et-working .steps .tab .top .left {
  display: flex;
  align-items: baseline;
}
#et-working .steps .tab .top .left .number {
  margin-right: 15px;
}
#et-working .steps .tab .top .left .number,
#et-working .steps .tab .top .left p {
  font-size: 20px;
}
#et-working .steps .tab .top .left p {
  color: #fff;
  text-align: left;
}
#et-working .steps .tab .top .right::before {
  content: "+";
  font-size: 34px;
}
#et-working .steps .tab.active .top .right::before {
  content: "-";
  font-size: 34px;
}
#et-working .steps .tab .bottom {
  padding: 0 72px 36px;
  margin-top: 24px;
  display: none;
}
#et-working .steps .tab .bottom .list {
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  text-align: left;
  padding: 35px 71px;
}
#et-working .steps .tab .bottom .list .item {
  margin-bottom: 17px;
  display: flex;
  align-items: baseline;
}
#et-working .steps .tab .bottom .list .item:last-child {
  margin-bottom: 0;
}
#et-working .steps .tab .bottom .list .item .round {
  height: 10px;
  width: 10px;
  background: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 15px;
}
#et-working .steps .tab .bottom .list .item .text {
  line-height: 1.6rem;
  width: fit-content;
}
#et-working .steps .tab .bottom .boxes {
  display: flex;
}
#et-working .steps .tab .bottom .boxes .box {
  background: #fff;
  width: 15%;
  padding: 26px 13px;
  border-radius: 7px;
  margin-right: 2%;
}
#et-working .steps .tab .bottom .boxes .box:last-child {
  margin-right: 0;
}
#et-working .steps .tab .bottom .boxes .box .round {
  height: 45px;
  width: 45px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 19px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);
  color: #444;
}
#et-working .steps .tab .bottom .boxes .box p {
  font-size: 15px;
}
#get-started {
  padding: 80px 0;
}
#get-started .box {
  background: #444;
  display: flex;
  align-items: center;
  padding: 130px 84px;
  justify-content: space-between;
  border-radius: 7px;
}
#get-started .box h4 {
  color: #fff;
  font-size: 39px;
  letter-spacing: 0.01rem;
}
#get-started .box .button {
  background: #fff;
  padding: 20px 30px;
  border-radius: 7px;
  letter-spacing: 0.01rem;
  display: inline-block;
}
#motive {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#motive .box {
  padding: 110px 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
  border-radius: 7px;
}
#motive .box .right {
  width: 49%;
  display: none;
}
#motive .box .right img {
  display: block;
  width: 100%;
}
#motive .box .top {
  font-size: 24px;
  color: #444;
  letter-spacing: 0.02rem;
}
#motive .box h2 {
  background: linear-gradient(120deg, #379eff 0, #00bcd4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  line-height: 3.1rem;
  margin: 12px 0 25px;
}
#motive .box p {
  font-size: 17px;
  width: 100%;
  letter-spacing: 0.02rem;
}
#motive .box .left {
  text-align: left;
}
#after {
  padding: 80px 0;
}
#after .head {
  text-align: center;
  margin-bottom: 60px;
}
#after .head h2 {
  line-height: 3.5rem;
}
#after .container {
  display: flex;
  justify-content: center;
}
#after .container .box {
  padding: 46px;
  box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
    0 12px 90px 0 rgba(103, 151, 255, 0.11);
  border-radius: 7px;
  margin-right: 50px;
  width: 27%;
  text-align: center;
  background: #fff;
}
#after .container .box:last-child {
  margin-right: 0;
}
#after .container .box .heading {
  font-size: 20px;
  letter-spacing: 0.01rem;
  display: block;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
  width: 65%;
  margin: 0 auto;
  line-height: 1.6rem;
}
#after .container .box ul {
  text-align: left;
  margin-top: 28px;
}
#after .container .box ul li {
  color: #6a6a6a;
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
}
#after .container .box ul li:last-child {
  margin-bottom: 0;
}
#after .container .box:last-child ul li .icon {
  color: #52af50;
}
#after .container .box ul li .text {
  line-height: 1.6rem;
}
#after .container .box ul li .icon {
  font-size: 20px;
  margin-right: 15px;
  color: #f4c136;
}
#work-tech #spotlight {
  background: linear-gradient(#37cdfc, #4069fa);
}
#work-tech #spotlight .main {
  position: relative;
}
#work-tech #spotlight .main::before {
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/spotlight-bg1.svg")
    no-repeat;
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  height: 248px;
  left: 269px;
  top: 73px;
}
#work-tech #spotlight .main::after {
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/spotlight-bg2.svg")
    no-repeat;
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  height: 341px;
  right: 111px;
  bottom: 12px;
  width: 386px;
  background-size: 89%;
}
#w-t-highlights {
  padding: 133px 0;
}
#w-t-highlights .row {
  display: flex;
  align-items: center;
  justify-content: center;
}
#w-t-highlights .row .left {
  border: 26px solid #368dee;
  position: relative;
  margin-right: 12%;
}
#w-t-highlights .row .left::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 446px;
  height: 446px;
  background: linear-gradient(#9be3fd, #b3cdf7);
  right: 69px;
  bottom: -69px;
}
#w-t-highlights .row .left::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 446px;
  height: 446px;
  background: linear-gradient(#b3cdf7, #9be3fd);
  left: 69px;
  top: -69px;
}
#w-t-highlights .row .box img {
  display: block;
  width: 100%;
}
#w-t-highlights .row .right {
  width: 32%;
}
#w-t-highlights .row .right h2 {
  letter-spacing: 0.03rem;
  margin-bottom: 44px;
}
#w-t-highlights .row .right ul li {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}
#w-t-highlights .row .right ul li .icon {
  background: #2196f3;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  font-size: 13px;
  margin-right: 17px;
}
#w-t-highlights .row .right ul li .text {
  color: #6b6b6b;
  display: block;
  width: fit-content;
  line-height: 1.4rem;
}
#startup-list {
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/services-bg.svg"),
    linear-gradient(#f25d74, #f89644) !important;
}
#startup-list .service-slider .item .bottom {
  text-align: center;
}
#startup-list .service-slider .item .top {
  background: #fff;
  height: 240px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#startup-list .service-slider .item .top img {
  width: 48% !important;
}
#startup-list,
#w-t-services {
  padding: 124px 0 132px;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/services-bg.svg"),
    linear-gradient(#404040, #030303);
  background-repeat: no-repeat !important;
  background-position: right !important;
}
#startup-list .head,
#w-t-services .head {
  margin-bottom: 100px;
  text-align: center;
}
#startup-list .head h2,
#w-t-services .head h2 {
  color: #fff;
}
#startup-list .service-slider .item .top,
#w-t-services .service-slider .item .top {
  overflow: hidden;
}
#startup-list .service-slider .item .top img,
#w-t-services .service-slider .item .top img {
  width: 100%;
  display: block;
}
#w-t-services .service-slider .item .bottom h4 {
  line-height: 1.7rem;
}
#startup-list .service-slider .item .bottom,
#w-t-services .service-slider .item .bottom {
  padding-top: 26px;
}
#startup-list .service-slider .item .bottom h4,
#w-t-services .service-slider .item .bottom h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 21px;
  margin-bottom: 9px;
}
#startup-list .service-slider .item .bottom p,
#w-t-services .service-slider .item .bottom p {
  color: #fff;
}
#startup-list .item,
#w-t-services .item {
  padding: 0 18px;
}
#startup-list .slick-list,
#w-t-services .slick-list {
  margin: 0 -18px !important;
}
#startup-list .slick-dots,
#w-t-services .slick-dots {
  top: -79px;
  height: 35px;
}
#startup-list .slick-dots li.slick-active button:before,
#w-t-services .slick-dots li.slick-active button:before {
  color: #fff !important;
}
#startup-list .slick-dots li button:before,
#w-t-services .slick-dots li button:before {
  color: #f3f3f3 !important;
}
#w-t-products {
  padding-bottom: 100px;
}
#w-t-products .top-box {
  padding: 90px 0 228px;
  background: linear-gradient(#37ccfc, #3378f4);
  position: relative;
  z-index: -1;
}
#w-t-products .top-box .container {
  background: #fff;
  border-radius: 10px;
  padding: 84px 55px;
  display: flex;
}
#w-t-products .top-box h2 {
  color: #fff;
  margin-bottom: 44px;
  text-align: center;
}
#w-t-products .top-box .left {
  width: 20%;
  display: flex;
  padding-right: 49px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #cacaca;
}
#w-t-products .top-box .left img {
  display: block;
  width: 100%;
}
#w-t-products .top-box .right .title {
  display: block;
  font-size: 23px;
  margin-bottom: 20px;
}
#w-t-products .top-box .left .title {
  text-transform: uppercase;
  font-size: 28px;
  margin: 26px 0 11px;
  color: #444;
}
#w-t-products .top-box .left .desc {
  font-size: 16px;
  color: #444;
  text-align: center;
  line-height: 1.3rem;
}
#w-t-products .top-box .right {
  padding-left: 35px;
  width: 82%;
}
#w-t-products .top-box .right p {
  font-size: 18px;
}
#w-t-products .top-box .right .bottom {
  margin-top: 39px;
}
#w-t-products .top-box .right .bottom .title {
  margin-bottom: 25px;
}
#w-t-products .top-box .right .bottom ul {
  display: flex;
  flex-wrap: wrap;
}
#w-t-products .top-box .right .bottom ul li {
  display: inline-flex;
  align-items: baseline;
  margin: 0 25px 21px 0;
  width: 48%;
}
#w-t-products .top-box .right .bottom ul li:nth-child(2n) {
  margin-right: 0;
}
#w-t-products .top-box .right .bottom ul li .icon {
  background: #52af50;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  font-size: 13px;
  margin-right: 15px;
}
#w-t-products .top-box .right .bottom ul li .text {
  color: #444;
  font-size: 17px;
  width: fit-content;
  line-height: 1.5rem;
}
#w-t-products .slider {
  width: 92%;
  margin: -213px auto 0;
}
#w-t-products .slider .card {
  box-shadow: 0 3px 9px rgba(154, 180, 204, 0.2);
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#w-t-products .slider .card .top {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
#w-t-products .slider .card .top img {
  margin: 0 auto;
  display: block;
  width: 38%;
}
#w-t-products .slider .card .deatils {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #d2d2d2;
}
#w-t-products .slider .card .deatils .title {
  display: block;
  font-size: 22px;
  margin-bottom: 8px;
}
#w-t-products .slider .card .deatils .desc {
  display: block;
  font-size: 19px;
  color: #444;
}
#w-t-products .slick-track {
  padding: 58px 0 !important;
}
#w-t-products .item {
  padding: 0 18px;
}
#w-t-products .slick-list {
  margin: 0 -18px;
}
.slick-dots li button:before {
  font-size: 13px !important;
  color: #9c9c9c !important;
}
.slick-dots li.slick-active button:before {
  font-size: 19px !important;
}
#w-t-products .slick-dots li.slick-active button:before {
  color: #2196f3 !important;
}
#w-t-products .flow {
  background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/flow.svg");
  bottom: -90px;
  height: 10vw;
  z-index: 1;
  background-size: 1399px 98px;
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 0;
}
#w-t-process {
  padding: 90px 0;
}
#w-t-process .head {
  text-align: center;
  margin-bottom: 58px;
}
#w-t-process .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#w-t-process .container .item {
  background: #fff;
  padding: 54px 48px;
  width: 31%;
  text-align: center;
  margin-top: 40px;
  box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
}
#w-t-process .container .item:nth-child(-n + 3) {
  margin-top: 0;
}
#w-t-process .container .item .icon {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#3378f4, #37ccfc);
  color: #fff;
  font-size: 40px;
}
#w-t-process .container .item .icon img {
  width: 41%;
  display: block;
}
#w-t-process .container .item h4 {
  margin: 30px 0 20px;
  font-size: 22px;
  letter-spacing: 0.01rem;
  line-height: 1.9rem;
}
#w-t-about {
  padding: 120px 0;
}
#w-t-about .head {
  margin-bottom: 40px;
  text-align: center;
}
#w-t-about .head h2 {
  margin-bottom: 20px;
}
#w-t-about .head p {
  width: 60%;
  margin: 0 auto;
  font-size: 17px;
}
#w-t-about .main {
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/rect-shapes.svg")
    no-repeat;
  background-position: center;
  background-size: 92%;
}
#w-t-about .main img {
  display: block;
  width: 70%;
  transform: translateX(30%);
  border-radius: 8px;
}
#startup .st-c,
#wt-get-quote,
#wt-hiring {
  background: linear-gradient(#14557a, #7fcfc6);
  padding: 110px 0;
  color: #fff;
  text-align: center;
}
#wt-hiring {
  background: linear-gradient(#2b5676, #4d4375) !important;
}
#startup .st-c {
  background: linear-gradient(#29323c, #475462) !important;
  padding: 120px 0;
}
#wt-get-quote .top-button {
  background: #fff;
  color: #14557a;
  padding: 20px 48px;
  border-radius: 5px;
  font-size: 24px;
  display: inline-block;
  cursor: pointer;
}
#startup .st-c h2,
#wt-get-quote h2,
#wt-hiring h2 {
  color: #fff;
  margin: 48px 0 22px;
}
#startup .st-c p,
#wt-get-quote p,
#wt-hiring p {
  color: #fff;
  font-size: 17px;
  width: 52%;
  margin: 0 auto;
}
#wt-get-quote .bottom .label {
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.02rem;
  padding: 10px;
  border: 2px solid #73b0b8;
  border-radius: 5px 7px;
  margin: 48px 0;
}
#wt-get-quote .bottom ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  border-radius: 7px;
  flex-wrap: wrap;
}
#wt-get-quote .bottom ul li {
  width: 49%;
  background: #3a7b91;
  border-radius: 7px;
  padding: 20px;
  margin-bottom: 22px;
  border-right: unset;
}
#wt-hiring .bottom ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #78b9bd;
  padding: 19px 21px;
  width: 100%;
  margin: 0 auto;
  border-radius: 7px;
}
#wt-hiring .bottom ul {
  background: #5f6289 !important;
  margin: 44px 0;
}
#wt-hiring .bottom ul li {
  padding: 9px 40px;
  border-right: 1px solid #fff;
  font-size: 17px;
}
#wt-get-quote .bottom ul li:last-child,
#wt-hiring .bottom ul li:last-child {
  border-right: unset;
}
#wt-hiring .bottom .label {
  margin: 0 !important;
}
#startup .st-c .bottom .label,
#wt-hiring .bottom .label {
  background: #fff;
  color: #3f4b75;
  padding: 17px 46px;
  border-radius: 5px;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  margin: 44px 0;
}
#wt-hiring .top-button {
  background: #486685;
  color: #fff;
  padding: 10px 12px;
  border-radius: 7px;
  font-size: 17px;
  display: inline-block;
}
#wt-projects {
  padding: 100px 0 145px;
}
#wt-projects .slider {
  text-align: center;
}
#wt-projects .slider .head {
  margin-bottom: 56px;
}
#wt-projects .slider .slick-track {
  display: flex !important;
}
#wt-projects .slider .slick-slide {
  height: inherit;
  display: flex !important;
}
#wt-projects .slider .slick-slide div {
  display: flex !important;
}
#wt-projects .slider .slider-items .content-card {
  display: flex !important;
  padding: 0 22px;
}
#wt-projects .slider .slider-items .content-card .item-slide,
#wt-projects.projects-single .item-container .content-card {
  background: #f2fbf6;
  padding: 55px 42px;
  border-radius: 8px;
  flex-direction: column;
}
#wt-projects .slick-list {
  margin: 0 -22px !important;
}
#wt-projects .slider .slider-items .content-card .item-slide img,
#wt-projects.projects-single .item-container .content-card img {
  display: block;
  width: 53%;
  margin: 0 auto;
}
#wt-projects .slider .slider-items .content-card .item-slide h4,
#wt-projects.projects-single .item-container .content-card h4 {
  font-size: 23px;
  letter-spacing: 0.02rem;
  margin: 25px 0 11px;
}
#wt-projects .slider .slider-items .content-card .item-slide .type,
#wt-projects.projects-single .item-container .content-card .type {
  display: block;
  color: #444;
  font-size: 17px;
  margin-bottom: 22px;
}
#wt-projects .slider .slider-items .content-card .item-slide p,
#wt-projects.projects-single .item-container .content-card p {
  font-size: 17px;
}
#wt-projects .slider .botton {
  display: inline-block;
  background: #303030;
  padding: 13px 45px;
  border-radius: 5px;
  color: #fff;
  margin-top: 85px;
  font-size: 17px;
}
#wt-projects .slick-dots {
  bottom: -45px;
}
#wt-refer {
  padding: 100px 0;
  background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/client-service-bg.svg"),
    url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/client-service-bg.svg"),
    #1e1d28;
  background-position: 1598px 148px, -240px -143px;
  background-repeat: no-repeat;
  text-align: center;
}
#wt-refer h2 {
  color: #fff;
}
#wt-refer p {
  font-size: 19px;
  color: #aaabaa;
  width: 75%;
  margin: 28px auto 34px;
}
#wt-refer .button {
  background: #fff;
  color: #1e1d28;
  padding: 17px 46px;
  border-radius: 5px;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
}
.semi-spotlight #spotlight {
  min-height: unset;
  padding-bottom: 100px;
  background: linear-gradient(#96e15f, #64e3ab);
}
.semi-spotlight #spotlight::after {
  background: linear-gradient(#96e15f, #64e3ab);
  animation: unset;
  width: 100%;
}
.semi-spotlight #spotlight div.main {
  height: unset;
  text-align: center;
  margin-top: 59px;
}
.semi-spotlight #spotlight div.main::before {
  display: none;
}
.semi-spotlight #spotlight div.main h2 {
  color: #fff;
  margin-bottom: 18px;
}
.semi-spotlight #spotlight div.main p {
  color: #fff;
  font-size: 18px;
  width: 30%;
  margin: 0 auto;
}
#i-about #ab-details {
  padding: 90px 0;
}
#i-about #ab-details .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
#i-about #ab-details .container .content {
  width: 47%;
}
#i-about #ab-details .container .content h2 {
  font-size: 36px;
  margin-bottom: 50px;
}
#i-about #ab-details .container .content p {
  font-size: 19px;
}
#i-about #ab-details .container .content p {
  margin-bottom: 18px;
}
#i-about #ab-details .container .content p:last-child {
  margin-bottom: 0;
}
#i-about #ab-details .container .img {
  width: 46%;
}
#i-about #ab-details .container .img img {
  display: block;
  width: 100%;
}
#i-about #middle {
  text-align: center;
  padding: 50px 0;
}
#i-about #middle h2 {
  font-size: 36px;
  margin-bottom: 79px;
}
#i-about #middle .items {
  display: flex;
  justify-content: space-between;
}
#i-about #middle .items .item {
  width: 31%;
  border: 1px solid #efefef;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
}
#i-about #middle .items .item .top {
  display: block;
  padding: 22px 10px;
  background: #f9f9f9;
  color: #93e160;
  font-size: 22px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin-bottom: 20px;
}
#i-about #middle .items .item:nth-child(2) .top {
  color: #4f63ed;
}
#i-about #middle .items .item:nth-child(3) .top {
  color: #f89346;
}
#i-about #middle .items .item p {
  font-size: 17px;
  padding: 12px 33px 38px;
}
#i-about #middle .items .item .bottom {
  height: 9px;
  background: linear-gradient(#93e160, #64e3aa);
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
}
#i-about #middle .items .item:nth-child(2) .bottom {
  background: linear-gradient(#2bc2fb, #4f63ed);
}
#i-about #middle .items .item:nth-child(3) .bottom {
  background: linear-gradient(#f25f73, #f89346);
}
#i-about #bottom {
  text-align: center;
  padding: 150px 0 300px;
  background: #f9f9f9;
}
#i-about #bottom h2 {
  color: #444;
  position: relative;
  display: inline-block;
}
#i-about #bottom h2::before {
  content: open-quote;
  position: absolute;
  color: #8ce164;
  top: -24px;
  left: -53px;
  font-size: 83px;
}
#i-about #bottom h2::after {
  content: "";
  position: absolute;
  background: linear-gradient(#93e160, #64e3aa);
  height: 9px;
  width: 148px;
  bottom: -26px;
  right: 102px;
}
#i-about #footer {
  margin-top: -180px;
  padding-bottom: 66px;
}
#i-contact #form .row {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
}
#i-contact #form .row .left {
  width: 67%;
}
#i-contact #form .row .left .top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#i-contact #form .row .left .top input {
  display: block;
  border-bottom: 1px solid #c7c7c7;
  width: 47%;
  padding: 60px 0 11px;
  text-align: left;
  font-size: 17px;
  color: #444;
  line-height: 1.6rem;
}
#i-contact #form .row .left .top input:last-child {
  width: 100%;
}
#i-contact #form .row .left .news-letter {
  margin: 40px 0 20px;
  display: flex;
  align-items: center;
}
#i-contact #form .row .left .news-letter .box {
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 3px;
  border: 1px solid #c7c7c7;
  margin-right: 15px;
  cursor: pointer;
  overflow: hidden;
}
#i-contact #form .row .left .news-letter .box .icon {
  color: #fff;
  -webkit-text-stroke-width: 1px;
  font-size: 15px;
  background: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#i-contact #form .row .left .news-letter .text {
  font-size: 18px;
}
#i-contact #form .row .left .note {
  color: #9a9ea9;
  width: 70%;
  display: block;
  line-height: 1.6em;
}
#i-contact #form .row .left .button {
  display: inline-block;
  padding: 13px 50px;
  border-radius: 7px;
  background: #444;
  color: #fff;
  font-size: 17px;
  margin-top: 50px;
  cursor: pointer;
}
#i-contact #form .row .right {
  width: 26%;
}
#i-contact #form .row .right img {
  display: block;
  width: 100%;
}
#i-contact #offices {
  background: #f8fcf8;
  padding: 84px 70px;
}
#i-contact #offices .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#i-contact #offices .row .img {
  width: 19%;
}
#i-contact #offices .row .img img {
  display: block;
  width: 100%;
}
#i-contact #offices .row .lists {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#i-contact #offices .row .lists .item {
  width: calc(100% / 3);
  padding: 22px 68px;
  border-right: 1px solid #e0e4e0;
}
#i-contact #offices .row .lists .item:last-child {
  border-right: unset;
}
#i-contact #offices .row .lists .item .top {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
#i-contact #offices .row .lists .item .top h4 {
  font-size: 22px;
  margin-right: 18px;
  line-height: 2rem;
}
#i-contact #offices .row .lists .item .top .icon {
  color: #5bb6fb;
  font-size: 24px;
  justify-content: space-between;
}
#i-contact #offices .row .lists .item .bottom address {
  color: #979797;
  font-size: 18px;
  line-height: 1.8rem;
}
#i-contact #footer {
  padding: 70px 0;
}
#startup #spotlight {
  background: linear-gradient(#f25d74, #f78f4a);
}
#startup #spotlight .main .bottom .item:last-child {
  color: #f25d74;
}
#startup #st-wd {
  padding: 100px 0;
  text-align: center;
}
#startup #st-wd .head {
  margin-bottom: 48px;
}
#startup #st-wd .items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#startup #st-wd .items .item {
  width: 22%;
  box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
  border-radius: 10px;
  padding: 50px 25px;
  margin: 35px 35px 0 0;
  background: #fff;
}
#startup #st-wd .items .item:last-child {
  margin-right: 0 !important;
}
#startup #st-wd .items .item:nth-child(4n) {
  margin-right: 0;
}
#startup #st-wd .items .item:nth-child(-n + 4) {
  margin-top: 0;
}
#startup #st-wd .items .item .round {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: linear-gradient(#f25d74, #f78f4a);
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 26px;
}
#startup #st-wd .items .item h5 {
  font-size: 19px;
  letter-spacing: 0.02rem;
  margin: 21px 0 18px;
}
#startup #middle {
  padding: 120px 0;
}
#startup #middle .head {
  display: none;
  text-align: center;
  margin-bottom: 40px;
}
#startup #middle .row {
  display: flex;
  align-items: center;
  justify-content: center;
}
#startup #middle .row .left {
  border: 35px solid #8b4e35;
  position: relative;
  margin-right: 12%;
  width: 50%;
}
#startup #middle .row .left img {
  display: block;
  width: 100%;
}
#startup #middle .row .right {
  width: 32%;
}
#startup #middle .row .right ul li {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}
#startup #middle .row .right ul li:last-child {
  margin-bottom: 0;
}
#startup #middle .row .right ul li .icon {
  background: #4d2718;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  font-size: 16px;
  margin-right: 17px;
}
#startup #middle .row .right ul li .text {
  color: #444;
  font-size: 24px;
}
#startup .st-c h2 {
  margin-top: 0;
}
#startup .st-c .bottom .label {
  color: #475462 !important;
  margin: 34px 0 0 0;
}
#startup .st-c .bottom ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: 48px auto;
}
#startup .st-c .bottom ul li {
  width: 48%;
  background: #525c65;
  border-radius: 7px;
  padding: 20px;
  margin-bottom: 23px;
}
#startup .st-c .bottom ul li:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
#inv-reg.st-c {
  background: linear-gradient(#5f71bd, #9f68ea) !important;
}
#inv-reg.st-c .bottom ul li {
  background: #8a6ed5;
}
#inv-reg.st-c .bottom .label {
  color: #5f71bd !important;
}
#startup #s-details {
  padding: 100px 0;
}
#startup #s-details .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
#startup #s-details .container .content {
  width: 47%;
}
#startup #s-details .container .content h2 {
  margin-bottom: 30px;
}
#startup #s-details .container .content p {
  font-size: 19px;
}
#startup #s-details .container .content p:last-child {
  margin-top: 18px;
}
#startup #s-details .container .img {
  width: 46%;
}
#startup #s-details .container .img img {
  display: block;
  width: 100%;
}
#techcapital-events {
  background: #f9f9f9;
  padding: 100px 0 150px;
  text-align: center;
}
#techcapital-events .head {
  margin-bottom: 56px;
}
#techcapital-events .slider-items .item-slide {
  background: #fff;
  flex-direction: column;
}
#techcapital-events .slick-track {
  display: flex !important;
}
#techcapital-events .slick-track div {
  display: flex !important;
  height: inherit;
}
#techcapital-events .slider-items .content-card {
  padding: 0 20px;
  display: flex !important;
}
#techcapital-events.slick-list {
  margin: 0 -20px !important;
}
#techcapital-events .slider-items .item-slide .top img {
  display: block;
  width: 100%;
}
#techcapital-events .slider-items .item-slide .top {
  overflow: hidden;
  height: 187px;
  display: flex;
  align-items: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
#techcapital-events .slider-items .item-slide .bottom {
  padding: 30px;
  text-align: left;
  flex-direction: column;
}
#techcapital-events .slider-items .item-slide .bottom .date {
  display: block;
  font-size: 15px;
  margin-bottom: 4px;
}
#techcapital-events .slider-items .item-slide .bottom .location {
  display: block;
  font-size: 14px;
  color: #bcbcbc;
  line-height: 1.2rem;
}
#techcapital-events .slider-items .item-slide .bottom h4 {
  font-size: 22px;
  line-height: 2rem;
  margin: 14px 0 11px;
}
#techcapital-events .slider-items .item-slide .bottom .button {
  background: linear-gradient(#f25d74, #f89644);
  display: inline-block;
  padding: 8px 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}
#techcapital-events .slider .button {
  display: inline-block;
  background: #303030;
  padding: 13px 45px;
  border-radius: 5px;
  color: #fff;
  margin-top: 100px;
  font-size: 17px;
}
#techcapital-events .slick-dots {
  bottom: -60px;
}
#startup #footer {
  padding: 70px 0 30px;
}
#wt-projects.projects-single .item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#wt-projects.projects-single .item-container .content-card {
  width: 31.5%;
  text-align: center;
  margin-bottom: 33px;
}
[data-aos][data-aos][data-aos-duration="3100"],
body[data-aos-duration="3100"] [data-aos] {
  transition-duration: 3.1s;
}
[data-aos][data-aos][data-aos-delay="3100"],
body[data-aos-delay="3100"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="3100"].aos-animate,
body[data-aos-delay="3100"] [data-aos].aos-animate {
  transition-delay: 3.1s;
}
[data-aos][data-aos][data-aos-duration="3500"],
body[data-aos-duration="3500"] [data-aos] {
  transition-duration: 3.5s;
}
[data-aos][data-aos][data-aos-delay="3500"],
body[data-aos-delay="3500"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="3500"].aos-animate,
body[data-aos-delay="3500"] [data-aos].aos-animate {
  transition-delay: 3.5s;
}
[data-aos][data-aos][data-aos-duration="3800"],
body[data-aos-duration="3800"] [data-aos] {
  transition-duration: 3.8s;
}
[data-aos][data-aos][data-aos-delay="3800"],
body[data-aos-delay="3800"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="3800"].aos-animate,
body[data-aos-delay="3800"] [data-aos].aos-animate {
  transition-delay: 3.8s;
}
[data-aos][data-aos][data-aos-duration="4000"],
body[data-aos-duration="4000"] [data-aos] {
  transition-duration: 4s;
}
[data-aos][data-aos][data-aos-delay="4000"],
body[data-aos-delay="4000"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="4000"].aos-animate,
body[data-aos-delay="4000"] [data-aos].aos-animate {
  transition-delay: 4s;
}
[data-aos][data-aos][data-aos-duration="5000"],
body[data-aos-duration="5000"] [data-aos] {
  transition-duration: 5s;
}
[data-aos][data-aos][data-aos-delay="5000"],
body[data-aos-delay="5000"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="5000"].aos-animate,
body[data-aos-delay="5000"] [data-aos].aos-animate {
  transition-delay: 5s;
}
[data-aos][data-aos][data-aos-duration="6000"],
body[data-aos-duration="6000"] [data-aos] {
  transition-duration: 6s;
}
[data-aos][data-aos][data-aos-delay="6000"],
body[data-aos-delay="6000"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="6000"].aos-animate,
body[data-aos-delay="6000"] [data-aos].aos-animate {
  transition-delay: 6s;
}
[data-aos][data-aos][data-aos-duration="7000"],
body[data-aos-duration="7000"] [data-aos] {
  transition-duration: 7s;
}
[data-aos][data-aos][data-aos-delay="7000"],
body[data-aos-delay="7000"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="7000"].aos-animate,
body[data-aos-delay="7000"] [data-aos].aos-animate {
  transition-delay: 7s;
}
#spotlight div.main div.keys .top {
  display: flex;
  margin-right: 60px;
}
@media (max-width: 1440px) {
  #web_tech_schooling div.top_section div.right_section h2 {
    font-size: 50px;
  }
  #spotlight div.main {
    height: calc(100vh - 158.38px);
  }
  #spotlight div.main div.keys .top {
    margin-right: 31px;
  }
  .i-c-s #spotlight .main {
    height: calc(100vh - 147.95px);
  }
  .tp-com {
    padding: 85px 0 100px;
  }
  .tp-com .top {
    margin: 58px 0 68px;
  }
  #industry {
    padding: 93px 0;
  }
  #e-tp .container .box.left h2 {
    font-size: 39px;
  }
  #e-tp .container .box.right .video-container {
    width: 100%;
  }
  #e-tp .container .box.right {
    padding: 0 149px 0 120px;
  }
  .tp-com h2 {
    font-size: 47px;
  }
  .tp-com .top .round {
    height: 164px;
    width: 164px;
    border-width: 1px;
    margin-right: 46px;
    font-size: 16.4px;
  }
  .tp-com .top .round:last-child {
    margin-right: 0;
  }
  .tp-com .bottom .container {
    padding: 0 74px;
    width: 88%;
    margin: 0 auto;
  }
  .tp-com .bottom .container .box {
    padding: 61px 0;
  }
  .tp-com .bottom .container .box .icon img {
    margin: 0 auto 23px;
  }
  .tp-com .bottom .container .box p {
    font-size: 22px;
  }
  #innovation .video-container {
    width: 78%;
  }
  #innovation .head h2 {
    font-size: 42px;
  }
  #menu .close-icon {
    left: 132px;
  }
  #i-contact #offices .row .lists .item {
    padding: 22px 55px;
  }
  #i-contact #offices .row .img {
    display: none;
  }
  #i-contact #offices .row .lists {
    width: 100%;
  }
  #i-contact #offices .row .lists .item .top {
    align-items: baseline;
  }
  #after .container .box {
    width: 33%;
  }
  #et-working .steps .tab .bottom {
    padding: 0 59px 36px;
    margin-top: 23px;
  }
  #et-working .items .item {
    width: 27%;
    margin: unset !important;
    margin: 23px 23px 0 0 !important;
  }
  #et-working .items .item:last-child,
  #et-working .items .item:nth-child(3n) {
    margin-right: 0 !important;
  }
  #et-working .items .item:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
  #ed-tech #why-ed-tech .row .item {
    padding: 16px 30px;
  }
  #ed-tech #e-t-about .left {
    width: 54%;
  }
  #ed-tech #e-t-about .right img {
    width: 78%;
  }
  #w-t-products .item {
    padding: 0 11px;
  }
  #w-t-products .slick-list {
    margin: 0 -11px;
  }
  #w-t-products .slider .card .deatils {
    padding: 16px 0;
  }
  #w-t-products .slider .card .deatils .desc {
    font-size: 16px;
    letter-spacing: 0.01rem;
  }
  #w-t-products .slider .card .deatils .title {
    margin-bottom: 7px;
  }
  #w-t-products .top-box .left .title {
    font-size: 24px;
  }
  #w-t-products .top-box .left .desc {
    text-align: center;
    line-height: 1.3rem;
  }
  #w-t-products .top-box .left {
    width: 21%;
  }
  #w-t-products .top-box .right {
    padding-left: 41px;
    width: 79%;
  }
  #w-t-products .top-box .container {
    padding: 76px 55px;
  }
  #wt-get-quote .top-button {
    padding: 17px 46px;
    border-radius: 5px;
    font-size: 18px;
  }
  #wt-get-quote .bottom .label {
    font-size: 16px;
    padding: 10px 13px;
  }
  #wt-get-quote .bottom ul,
  #wt-hiring .bottom ul {
    background: unset !important;
    flex-wrap: wrap;
    width: 90%;
    padding: unset;
  }
  #wt-get-quote .bottom ul li,
  #wt-hiring .bottom ul li {
    width: 48%;
    background: #5f6289;
    border-radius: 7px;
    padding: 20px;
    margin-bottom: 23px;
    border-right: unset;
  }
  #wt-get-quote .bottom ul li {
    background: #3a7b91 !important;
  }
  #wt-get-quote .bottom ul li:nth-last-child(-n + 2),
  #wt-hiring .bottom ul li:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
  #w-t-highlights .row .right {
    width: 40%;
  }
  #w-t-highlights .row .left {
    width: 36%;
    margin-right: 10%;
  }
  #w-t-highlights .row .left::after,
  #w-t-highlights .row .left::before {
    width: 383px;
    height: 383px;
  }
  #work-tech #spotlight .main::after {
    right: 41px;
    bottom: -58px;
  }
  #work-tech #spotlight .main::before {
    left: 145px;
    top: 22px;
  }
  #startup-list .service-slider .item .top {
    height: 230px;
  }
  #startup #s-details .container .img {
    width: 42%;
  }
  #startup #s-details .container .content {
    width: 52%;
  }
  #startup .st-c .bottom ul {
    width: 90%;
  }
  #startup #middle .row .left {
    border: 32px solid #8b4e35;
    margin-right: 11%;
    width: 43%;
  }
  #startup #middle .row .right ul li {
    margin-bottom: 30px;
  }
  #startup #middle .row .right ul li .text {
    font-size: 20px;
  }
  #startup .st-c p,
  #wt-get-quote p,
  #wt-hiring p,
  #wt-refer p {
    width: 56%;
  }
  .i-c-s #spotlight .main h1 {
    margin: 30px 0 15px;
    line-height: 3.5rem;
  }
  .i-c-s #spotlight .main p {
    width: 43%;
  }
  #i-about #middle .items .item {
    width: 32%;
  }
  .common-section .head span.top {
    font-size: 34px;
    letter-spacing: 0.05rem;
  }
  .common-section .head h2 {
    font-size: 60px;
    line-height: 5.5rem;
    margin: 52px 0 113px;
  }
  .common-section .head .button {
    padding: 26px 107px 26px 45px;
  }
  .common-section .head .button .round {
    height: 80px;
    width: 80px;
    top: -1px;
  }
  .common-section .head .button .text {
    font-size: 25px;
  }
  .common-section .head .button .round i {
    font-size: 29px;
  }
  #spotlight div.main h1 {
    font-size: 45px;
  }
  #spotlight header h1.logo {
    width: 5.7%;
  }
  #spotlight div.main h4 {
    font-size: 19px;
    margin: 23px 0 70px;
  }
  #spotlight div.main div.keys .round {
    margin-right: 31px;
    font-size: 16.4px;
    border-width: 1px;
    height: 150px;
    width: 150px;
  }
  #spotlight div.main .button {
    margin-top: 44px;
    padding: 23px 71px;
    font-size: 16px;
  }
  #about {
    padding: 130px 0;
    background-size: 54%;
    background-position: right -451px;
  }
  #about .top h2 {
    font-size: 35px;
    line-height: 3.3rem;
  }
  #about .top p {
    margin: 27px 0;
  }
  #about .items .item h3 {
    font-size: 24px;
    line-height: 2.3rem;
    margin: 29px 0 24px;
  }
  #about .items .item p {
    font-size: 17px;
  }
  #about .items .item .icon img {
    width: 60%;
  }
  #about .bg-animate {
    background-size: 54%;
    background-position: right -451px;
  }
  #about .items {
    margin-top: 87px;
  }
  #techiespark .right h2 {
    font-size: 41px;
  }
  #techiespark .right .bottom {
    font-size: 20px;
    margin: 12px 0 36px;
  }
  #techiespark .right .button {
    font-size: 16px;
    margin-top: 43px;
  }
  #techiespark .left .column {
    width: 41%;
  }
  #techiespark .right {
    width: 33.4%;
    transform: translateY(15px);
  }
  #techiespark-spotlight .tp-main h1 {
    font-size: 54px;
  }
  #techiespark-spotlight .tp-main h4 {
    margin-top: 23px;
  }
  #how-works .title {
    font-size: 33px;
    margin-bottom: 50px;
  }
  #how-works .items .item h3 {
    font-size: 24px;
    line-height: 2.3rem;
    margin: 29px 0 24px;
  }
  #how-works .items .item p {
    font-size: 17px;
  }
  #how-works .items .item .icon img {
    width: 65%;
  }
  #how-works .items .item {
    margin-bottom: 72px;
  }
  #techiespark-spotlight .tp-main {
    margin-top: 186px;
  }
}
@media (max-width: 1295px) {
  #startup #st-wd .items .item {
    margin: 35px 35px 0 0 !important;
    width: 26%;
    padding: 50px 18px;
  }
  #startup #st-wd .items .item:nth-child(3n) {
    margin-right: 0 !important;
  }
  #startup #st-wd .items .item:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
}
@media (max-width: 1280px) {
  #i-contact #form .row .left {
    width: 67%;
  }
  #i-contact #form .row .left .note {
    width: 79%;
  }
  #et-working .steps .tab .bottom .list {
    padding: 35px 48px;
  }
  #i-about #footer,
  #i-contact #footer,
  #startup #footer {
    padding: unset;
  }
  .uppercase {
    font-size: 40px;
  }
  #spotlight::after {
    width: 100%;
    background-image: linear-gradient(
        rgba(255, 74, 158, 0.2) 0,
        rgba(255, 74, 158, 0) 50%
      ),
      url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/spotlight.png");
    background-position: 30%;
    background-repeat: no-repeat;
    background-size: cover;
    animation-name: unset;
  }
  #spotlight div.main {
    height: unset;
    padding: 92px 0 155px;
  }
  #spotlight div.main h4 {
    margin: 27px 0 43px;
  }
  #spotlight div.main div.keys .round {
    margin-right: 19px;
  }
  #spotlight div.main::before {
    background: #fff;
  }
  #spotlight div.main div.keys .round:last-child {
    margin-right: 0;
  }
  #spotlight div.main div.keys {
    display: flex;
  }
  #spotlight div.main div.keys .top {
    display: flex;
    margin-right: 19px;
  }
  #about {
    background: unset;
  }
  #about .top {
    width: 100%;
    text-align: center;
  }
  #about .top h2 {
    font-size: 32px;
    line-height: 2.9rem;
  }
  #about .top p {
    margin: 15px auto;
    width: 75%;
    letter-spacing: 0.01rem;
    font-size: 16px;
  }
  #about .items .item a {
    font-size: 15px;
    margin-top: 25px;
  }
  #about .bg-animate {
    background: unset;
  }
  #about {
    padding-top: 79px;
    min-height: unset;
  }
  #about .items .item {
    width: 32%;
    text-align: center;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.07),
      0 12px 22px 0 rgba(103, 151, 255, 0.08);
    padding: 4% 3%;
  }
  #about .items .item .icon {
    margin: 0 auto;
    height: 60px;
    width: 60px;
    background: #fff;
  }
  #about .items .item h3 {
    font-size: 21px;
    line-height: 2rem;
    margin: 25px 0 16px;
  }
  #about .items .item p {
    font-size: 16px;
  }
  #about .items {
    margin-top: 53px;
  }
  #about .bg-animate,
  #about.animate .animate-layer-container {
    display: none;
  }
  #initiative {
    background: -webkit-radial-gradient(
      top left,
      circle,
      #4a42ec 0,
      #521d91 100%
    );
    background: radial-gradient(circle at top left, #4a42ec 0, #521d91 100%);
    background-color: #521d91;
    min-height: unset;
    width: 93%;
    box-shadow: 0 48px 48px -32px rgba(23, 16, 159, 0.2),
      0 96px 96px -64px rgba(23, 16, 159, 0.4);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    position: relative;
  }
  #initiative::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: #cfb5f1f0;
    right: -100px;
    bottom: -47px;
  }
  .common-section {
    padding: 127px 0;
  }
  .common-section .head h2 {
    font-size: 46px;
    line-height: 4.4rem;
    margin: 30px 0 47px;
  }
  .common-section .head span.top {
    font-size: 29px;
  }
  .common-section .head .button {
    padding: 26px 99px 26px 43px;
  }
  .common-section .head .button .text {
    font-size: 24px;
  }
  #support.common-section .head .button .round,
  #why-industry.common-section .head .button .round {
    margin: 0 auto;
  }
  #why-industry.common-section .head .button .round {
    background: #fff;
  }
  #why-industry.common-section .head .button .round i {
    color: #61dda7;
  }
  #why-industry.common-section .head .button .round .common-section {
    min-height: unset;
  }
  #why-industry.common-section {
    width: 93%;
    background: -webkit-radial-gradient(
      top left,
      circle,
      #34dd87 0,
      #34ddbf 100%
    );
    background: radial-gradient(circle at top left, #34dd87 0, #34ddbf 100%);
    background-color: #34dd87;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    position: relative;
    margin-left: auto;
    margin-bottom: 75px;
    min-height: unset;
  }
  #support.common-section .head h2,
  #support.common-section .head span.top,
  #why-industry.common-section .head h2,
  #why-industry.common-section .head span.top {
    color: #fff;
  }
  #why-industry.common-section:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 800px;
    height: 800px;
    border-radius: 2000px;
    background: -webkit-linear-gradient(
      305deg,
      rgba(52, 221, 135, 0) 55%,
      rgba(52, 221, 135, 0.5) 100%
    );
    background: linear-gradient(
      145deg,
      rgba(52, 221, 135, 0) 55%,
      rgba(52, 221, 135, 0.5) 100%
    );
    right: -32px;
    bottom: 152px;
  }
  #why-industry.common-section .head .button {
    background: unset;
  }
  #why-industry.common-section.common-animate .grad-layer {
    display: none;
  }
  #techiespark {
    height: unset;
    padding: 120px 0 80px;
  }
  #techiespark .right h2 {
    font-size: 37px;
  }
  #techiespark .right p {
    font-size: 17px;
  }
  #techiespark .right .bottom {
    font-size: 18px;
    margin: 12px 0 28px;
  }
  #techiespark .right .button {
    font-size: 15px;
    margin-top: 40px;
  }
  #why-industry.common-section:after {
    right: -32px;
    bottom: 152px;
  }
  #industry::after {
    width: 100%;
    background: unset;
  }
  #industry {
    min-height: unset;
    padding-top: 10px;
    padding-bottom: unset;
    background: unset;
  }
  #industry .head h3 {
    font-size: 38px;
    letter-spacing: 0.06rem;
    font-family: product_sansbold;
    color: #343433;
  }
  #industry .head .bottom {
    width: 90%;
    margin: 16px auto 0;
    font-size: 18px;
    line-height: 1.64rem;
    color: #343433;
  }
  #industry .contents {
    width: 100%;
    background: #fff;
    border-radius: 7px;
    padding-bottom: 90px;
    display: flex;
    justify-content: space-between;
  }
  #industry .contents .content-box {
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    width: 48%;
    padding: 60px 50px;
    border-radius: 7px;
  }
  #industry .contents .content-box .items .item {
    width: 100%;
    margin-bottom: 20px;
  }
  #industry .contents .content-box .items .item:nth-last-child(-n + 2) {
    margin-bottom: 24px;
  }
  #industry .contents .content-box .items .item:last-child {
    margin-bottom: 0;
  }
  #industry .contents .content-box h4 {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.02rem;
    color: #343434;
    line-height: 1.5rem;
  }
  #industry .contents .content-box h5 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #3fa5fb;
    line-height: 1.6rem;
  }
  #industry .contents .content-box .items .item .line {
    background: #343434;
    width: 7px;
    margin-right: 30px;
    height: 7px;
    border-radius: 50%;
  }
  #industry .head {
    text-align: center;
    margin-bottom: 49px;
  }
  #industry .contents .content-box:first-child {
    margin-bottom: 0;
  }
  #industry .button {
    animation: unset;
    width: 53px;
    height: 53px;
    line-height: unset;
    display: none;
    align-items: center;
    justify-content: center;
    background: #484848;
    transform: translate(-50%);
    right: 50%;
    left: 50%;
    bottom: -26px;
  }
  #industry .button i {
    font-size: 23px;
    -webkit-text-stroke-width: 1.5px;
    color: #fff;
  }
  #industry svg {
    position: absolute;
    bottom: -864px;
    width: 49%;
    transform: rotate(-90deg);
    z-index: -1;
    left: -26px;
  }
  #support.common-section.common-animate .grad-layer {
    display: none;
  }
  #support.common-section {
    background: linear-gradient(237deg, #46b3ff 0, #0752fe 100%);
    border-radius: 7px;
    margin-bottom: 60px;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    min-height: unset;
  }
  #support.common-section .head .button {
    background: unset;
    display: none;
  }
  #support.common-section .head .button .round {
    background: #fff;
    position: absolute;
    z-index: 10;
    transform: translate(-50%);
    right: 50%;
    left: 50%;
  }
  #support.common-section .head .button .round i {
    color: #308cfa;
  }
  #missions {
    background: #fff;
    margin-bottom: 134px;
    min-height: unset;
  }
  #missions.mission-animate::after {
    background: #fff;
  }
  #missions .items {
    flex-wrap: wrap;
    margin-top: -119px;
    justify-content: space-between;
  }
  #missions .items .item {
    width: 32%;
    box-shadow: 0 8px 60px 0 rgba(209, 223, 255, 0.11),
      0 12px 90px 0 rgba(141, 147, 160, 0.11);
    border-radius: 7px;
    padding: 52px 44px 44px;
  }
  #missions .items .item p {
    font-size: 16px;
    letter-spacing: 0.04rem;
    margin-bottom: 14px;
  }
  /* #missions .items .item:first-child img {
        width: 45%;
    } */
  #missions .items .item img {
    width: 60%;
    margin: 0 auto 17px;
  }
  #missions .items .item .button {
    margin-top: 22px;
    box-shadow: 0 1px 35px -13px rgba(61, 61, 61, 0.6);
  }
  footer .box {
    width: 100%;
    box-shadow: unset;
  }
  /* footer .box img {
        width: 7%;
    } */
  footer .box .address {
    width: 95%;
    font-size: 15px;
    margin: 17px 0;
  }
  footer .box .social .item {
    width: 40px;
    height: 40px;
    border-radius: 15px;
  }
  footer .box .bottom a {
    font-size: 15px;
  }
  footer .box .bottom {
    margin-top: 20px;
    /* width: 248px; */
  }
}
@media (max-width: 1122px) {
  #et-working .steps .tab .bottom .boxes .box {
    width: 22%;
    margin-right: 4%;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(-n + 5) {
    margin-top: 2.5%;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(-n + 4) {
    margin-top: 0;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(5n) {
    margin-right: 4%;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 1100px) {
  #missions .items .item {
    width: 32.5%;
    padding: 41px 32px 39px;
  }
  .uppercase {
    font-size: 39px;
  }
  #e-tp .container .box.left h2 {
    font-size: 37px;
  }
  #e-tp .container .box.left p {
    width: 78%;
  }
  #e-tp {
    min-height: unset;
  }
  #e-tp .container {
    height: unset;
  }
  #e-tp .container .box.right {
    padding: 104px 110px;
    min-height: unset;
  }
  .tp-com {
    min-height: unset;
    padding: 80px 0 125px;
  }
  .tp-com h2 {
    font-size: 40px;
  }
  .tp-com .top {
    margin: 50px 0 60px;
  }
  .tp-com .top .round {
    height: 160px;
    width: 160px;
    margin-right: 32px;
    font-size: 15.4px;
  }
  #tech-edu.tp-com .bottom .container,
  #tech-events.tp-com .bottom .container,
  .tp-com .bottom .container {
    padding: 0;
    background: unset;
    width: 100%;
  }
  #tech-edu.tp-com .bottom .container .box,
  #tech-events.tp-com .bottom .container .box,
  .tp-com .bottom .container .box {
    padding: 71px 39px;
    background: #f09998;
    width: 31%;
  }
  #tech-edu.tp-com .bottom .container .box {
    background: #68e8a2;
  }
  #tech-events.tp-com .bottom .container .box {
    background: #44cae0;
  }
  .tp-com .bottom .container .box p {
    font-size: 20px;
  }
  #s-acce,
  #tech-edu,
  #tech-events {
    background: unset;
    position: relative;
  }
  #s-acce::after {
    background: linear-gradient(#ec6f9d, #ec8a6b);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 68%;
    z-index: -100;
  }
  #tech-edu::after {
    background: linear-gradient(#94e160, #64e3aa);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 68%;
    z-index: -100;
  }
  #tech-events::after {
    background: linear-gradient(#6dee9a, #24b0e7);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 68%;
    z-index: -100;
  }
  #techiespark-spotlight {
    height: unset;
    -webkit-clip-path: unset;
    clip-path: unset;
  }
  #program.common-section {
    min-height: unset;
    width: 93%;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-bottom: 86px;
  }
  #program.common-section .head .button .round {
    background: #fff;
  }
  #program.common-section .head .button .round i {
    color: #444;
  }
  #innovation {
    min-height: unset;
    padding: 86px 0;
  }
  #innovation .head h2 {
    font-size: 35px;
  }
  #how-works .items .item {
    margin-bottom: 26px;
  }
  #i-contact #offices .row .lists .item {
    padding: 22px 29px;
  }
  #get-started .box {
    padding: 106px 71px;
  }
  #get-started .box h4 {
    font-size: 37px;
  }
  #languages {
    padding: 83px 0;
  }
  #languages .head {
    margin-bottom: 42px;
  }
  #after .container .box {
    width: 43%;
    margin-right: 39px;
  }
  #after .head {
    margin-bottom: 50px;
  }
  #et-working {
    padding: 87px 0;
  }
  #et-working .head {
    margin-bottom: 60px;
  }
  #ed-tech #expertise {
    padding: 88px 0;
  }
  #ed-tech #expertise .head {
    margin-bottom: 45px;
  }
  #ed-tech #e-t-about .right {
    width: 31%;
  }
  #ed-tech #e-t-about .left {
    width: 64%;
  }
  #wt-projects {
    padding: 81px 0 126px;
  }
  #wt-projects .slider .head {
    margin-bottom: 47px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide p,
  #wt-projects.projects-single .item-container .content-card p {
    font-size: 16px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide .type,
  #wt-projects.projects-single .item-container .content-card .type {
    font-size: 16px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide h4,
  #wt-projects.projects-single .item-container .content-card h4 {
    font-size: 22px;
  }
  #w-t-products .top-box {
    padding: 85px 0 219px;
  }
  #w-t-products .top-box .container {
    padding: 49px 37px;
    flex-wrap: wrap;
  }
  #w-t-products .top-box .left {
    width: 100%;
    border-right: unset;
    padding-right: unset;
    padding-bottom: 35px;
    border-bottom: 1px solid #cacaca;
  }
  #w-t-products .top-box .left img {
    width: 22%;
  }
  #w-t-products .top-box .right {
    padding: 41px 41px 0 41px;
    width: 100%;
  }
  #w-t-products .top-box .right .bottom {
    margin-top: 31px;
  }
  #w-t-products .top-box .right .bottom ul li {
    width: 48%;
  }
  #w-t-products .top-box .right .title {
    font-size: 22px;
    margin-bottom: 17px;
  }
  #w-t-products .top-box .right .bottom ul li .text {
    font-size: 16px;
  }
  #w-t-products .top-box .left .title {
    font-size: 25px;
  }
  #w-t-process .container .item {
    margin-top: 25px !important;
  }
  #w-t-process .container .item:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
  #wt-hiring .top-button {
    font-size: 16px;
  }
  #wt-get-quote h2,
  #wt-hiring h2 {
    margin: 31px 0 19px;
  }
  #startup .st-c,
  #wt-get-quote,
  #wt-hiring,
  #wt-refer {
    padding: 103px 0;
  }
  #wt-get-quote .bottom ul li,
  #wt-hiring .bottom ul li {
    margin-bottom: 22px;
    font-size: 16px;
    width: 49%;
  }
  #w-t-process .container .item {
    width: 48%;
  }
  #w-t-process .container .item .icon {
    height: 70px;
    width: 70px;
    font-size: 36px;
  }
  #w-t-highlights .row .right ul li .icon {
    margin-right: 11px;
  }
  #w-t-highlights .row .right h2 {
    text-align: center;
  }
  #w-t-highlights .row .right {
    width: 90%;
    margin: 0 auto;
  }
  #w-t-highlights .row .right ul li {
    width: 48%;
    box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
    padding: 16px 19px;
    background: #fff;
    border-radius: 7px;
    font-size: 16px;
  }
  #w-t-highlights .row .right ul li .text {
    letter-spacing: 0.02rem;
  }
  #w-t-highlights .row .right ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #w-t-highlights .row .left {
    display: none;
  }
  #w-t-highlights {
    padding: 82px 0;
  }
  #work-tech #spotlight .main::after,
  #work-tech #spotlight .main::before {
    display: none;
  }
  #techcapital-events .slider-items .item-slide .bottom {
    padding: 47px 33px;
  }
  #startup-list .head,
  #w-t-services .head {
    margin-bottom: 64px;
  }
  #startup-list .slick-dots,
  #w-t-services .slick-dots {
    top: -52px;
  }
  #startup-list,
  #w-t-services {
    padding: 103px 0;
  }
  #startup #s-details .container .content h2 {
    margin-bottom: 24px;
  }
  #startup #s-details .container .content p {
    font-size: 17px;
  }
  #startup #s-details .container .img {
    width: 30%;
  }
  #startup #s-details .container {
    padding: 65px 60px;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    width: 100%;
    margin: 0 auto;
  }
  #startup #s-details .container .content {
    width: 65%;
  }
  #startup #middle .row .right ul li .icon {
    height: 23px;
    width: 23px;
    font-size: 13px;
  }
  #startup #middle .row .right ul li {
    margin-bottom: 27px;
  }
  #startup #middle .row .right ul li .text {
    font-size: 18px;
  }
  #startup #middle {
    padding: 100px 0;
  }
  #startup .st-c {
    padding: 103px 0;
  }
  #startup .st-c .bottom ul {
    width: 97%;
  }
  #startup .st-c .bottom ul li {
    margin-bottom: 22px;
    font-size: 16px;
    width: 49%;
  }
  #startup #st-wd .items .item {
    width: 31%;
    margin: 30px 30px 0 0 !important;
  }
  .i-c-s #spotlight .main p {
    width: 71%;
  }
  .i-c-s #spotlight .main {
    padding: 116px;
    height: unset;
  }
  #spotlight {
    min-height: unset;
  }
  .semi-spotlight #spotlight {
    padding-bottom: 0;
  }
  .semi-spotlight #spotlight div.main {
    margin-top: 0;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    font-size: 34px;
  }
  #i-about #middle h2 {
    margin-bottom: 48px;
  }
  #i-about #middle .items .item .top {
    font-size: 18px;
    padding: 18px 10px;
    margin-bottom: 18px;
  }
  .e-t-h2 {
    font-size: 36px;
  }
  #i-about #ab-details .container .content p {
    font-size: 17px;
  }
  #support.common-section {
    margin-bottom: 60px;
  }
  #how-works .items .item {
    width: 32%;
    text-align: center;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.07),
      0 12px 22px 0 rgba(103, 151, 255, 0.08);
    padding: 4% 3%;
  }
  #how-works .items .item .icon {
    height: 60px;
    width: 60px;
    background: #fff;
    margin: 0 auto;
  }
  #how-works .items .item h3 {
    font-size: 21px;
    line-height: 2rem;
    margin: 25px 0 16px;
  }
  #how-works .items .item p {
    font-size: 16px;
  }
  #how-works .title {
    font-size: 30px;
    text-align: center;
  }
  #techiespark-spotlight {
    position: relative;
    background-image: linear-gradient(
      147deg,
      #008a1c 0,
      #a0cf00 100%
    ) !important;
    height: unset;
  }
  #techiespark-spotlight .tp-main {
    margin-top: unset;
    padding: 137px 0 222px;
  }
  #techiespark-spotlight header {
    padding-top: 58px;
  }
  #techiespark-spotlight .bottom {
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/flow.svg");
    bottom: -13px;
    height: 10vw;
    z-index: 1;
    background-size: 1399px 98px;
    display: block;
    position: absolute;
    pointer-events: none;
    width: 100%;
  }
  #how-works {
    transform: unset;
    padding-top: 46px;
  }
}
@media (max-width: 980px) {
  #web_tech_schooling div.top_section div.right_section h2 {
    font-size: 36px;
  }
  #web_tech_schooling div.bottom_section div.cards div.content_container h3 {
    font-size: 18px;
  }
  #web_tech_schooling div.bottom_section div.cards div.content_container p {
    font-size: 16px;
  }
  #i-contact #form .row .right {
    display: none;
  }
  #i-contact #form .row .left {
    width: 100%;
  }
  #i-contact #offices .row .lists .item .bottom address {
    font-size: 17px;
  }
  #i-contact #offices .row .lists .item .top h4 {
    font-size: 21px;
  }
  .uppercase {
    font-size: 30px;
  }
  #wt-hiring .bottom ul {
    margin-top: 32px;
  }
  #wt-projects.projects-single .item-container .content-card {
    width: 48%;
  }
  #techiespark-spotlight .tp-main p {
    width: 60%;
    margin-top: 20px;
  }
  #e-tp .container .box.right .video-container {
    margin-bottom: 40px;
  }
  #e-tp .container .box.left h2 {
    font-size: 32px;
  }
  #e-tp .container .box.right {
    padding: 78px 77px;
    min-height: unset;
  }
  .tp-com .top .round {
    height: 140px;
    width: 140px;
    margin-right: 26px;
    font-size: 14px;
  }
  .tp-com .bottom .container .box p {
    font-size: 18px;
  }
  .tp-com .bottom .container .box {
    padding: 60px 39px;
    width: 31%;
  }
  .tp-com h2 {
    font-size: 38px;
  }
  #innovation .video-container {
    width: 87%;
  }
  #innovation .head {
    text-align: center;
    margin-bottom: 27px;
  }
  #innovation .head h2 {
    font-size: 33px;
  }
  #menu .close-icon {
    left: 115px;
    top: 92px;
  }
  #menu .container .item .menu,
  #menu .container .item .number {
    font-size: 23px;
  }
  #menu .container .item .number {
    font-size: 23px;
    margin-right: 58px;
  }
  #menu .container .item {
    margin-bottom: 40px;
  }
  #i-contact #offices {
    padding: 64px 70px;
  }
  #i-contact #offices .row .lists .item {
    width: 50%;
    border-right: unset;
    border-bottom: 1px solid #e0e4e0;
    padding: 35px 33px;
  }
  #i-contact #offices .row .lists .item:last-child {
    margin-top: 0;
    border-bottom: unset;
  }
  #i-contact #offices .row .lists .item .top {
    margin-bottom: 16px;
  }
  #i-contact #form .row .left .top input {
    padding-top: 56px;
  }
  #i-contact #form .row .left .note {
    width: 100%;
    margin-bottom: 16px;
  }
  #get-started .box {
    display: block;
    text-align: center;
    padding: 80px 68px;
  }
  #get-started .box .button {
    margin-top: 35px;
    padding: 18px 29px;
  }
  #get-started .box h4 {
    font-size: 34px;
  }
  #languages .slick-prev {
    left: 14px;
  }
  #languages .slick-next {
    right: 17px;
  }
  #languages .slick-list {
    margin: 0 66px !important;
  }
  #languages .item {
    padding: 0 18px;
  }
  #after .container .box {
    width: 48%;
    margin-right: 26px;
  }
  #et-working .steps .tab .bottom .boxes .box {
    width: 30%;
    margin-right: 5%;
    margin-top: 5% !important;
  }
  #et-working .steps .tab .bottom {
    margin-top: 19px;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(4n),
  #et-working .steps .tab .bottom .boxes .box:nth-child(5n) {
    margin-right: 5%;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(3n) {
    margin-right: 0;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
  #motive .box .right {
    display: none;
  }
  #motive .box p {
    width: 100%;
  }
  #motive .box {
    padding: 87px 77px;
  }
  #motive .box h2 {
    font-size: 30px;
    line-height: 2.8rem;
    margin: 12px 0 22px;
  }
  #et-working .steps .tab {
    margin-bottom: 14px;
  }
  #et-working {
    padding: 77px 0;
  }
  #et-working .head {
    margin-bottom: 48px;
  }
  #et-working .items .item {
    width: 45%;
    padding: 50px 25px;
  }
  #et-working .items .item:nth-child(-n + 3) {
    margin: unset !important;
    margin: 20px 20px 0 0 !important;
  }
  #et-working .items .item:last-child,
  #et-working .items .item:nth-child(2n) {
    margin-right: 0 !important;
  }
  #et-working .items .item:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
  #ed-tech #expertise .items {
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
  }
  #ed-tech #expertise .items .item {
    width: 30%;
  }
  #ed-tech #expertise .items .item:nth-child(2) {
    margin: 0 25px;
  }
  #ed-tech #expertise .items .item:last-child {
    margin-top: 25px;
  }
  #ed-tech #why-ed-tech {
    padding: 68px 0;
  }
  #ed-tech #e-t-about {
    padding: 64px 0;
  }
  #ed-tech #e-t-about .left {
    width: 100%;
  }
  #ed-tech #e-t-about .right {
    display: none;
  }
  #wt-projects .slider .slider-items .content-card .item-slide img,
  #wt-projects.projects-single .item-container .content-card img {
    width: 49%;
  }
  #wt-projects .slider .slider-items .content-card .item-slide,
  #wt-projects.projects-single .item-container .content-card {
    padding: 50px 37px;
  }
  #w-t-products .top-box {
    padding: 83px 0 224px;
  }
  #w-t-products .top-box .right {
    width: 100%;
  }
  #wt-get-quote .top-button,
  #wt-projects .slider .botton,
  #wt-refer .button {
    padding: 15px 43px;
    font-size: 17px;
  }
  #startup .st-c,
  #wt-get-quote,
  #wt-hiring,
  #wt-refer {
    padding: 95px 0;
  }
  #wt-refer {
    background-image: unset;
  }
  #wt-get-quote .bottom ul li,
  #wt-hiring .bottom ul li {
    width: 80%;
    margin: 0 auto 12px !important;
  }
  #wt-get-quote .bottom ul li:last-child,
  #wt-hiring .bottom ul li:last-child {
    margin-bottom: 0 !important;
  }
  #w-t-about {
    padding: 86px 0;
  }
  #w-t-about .head p {
    width: 82%;
  }
  #techcapital-events .slider-items .content-card {
    padding: 0 19px;
  }
  #techcapital-events.slick-list {
    margin: 0 -19px !important;
  }
  #techcapital-events {
    padding: 91px 0 141px;
  }
  #startup-list .item,
  #w-t-services .item {
    padding: 0 11px;
  }
  #startup-list .slick-list,
  #w-t-services .slick-list {
    margin: 0 -11px !important;
  }
  #startup-list,
  #w-t-services {
    padding: 92px 0;
  }
  #startup-list .service-slider .item .top {
    height: 202px;
  }
  #startup-list .service-slider .item .bottom,
  #w-t-services .service-slider .item .bottom {
    padding-top: 20px;
  }
  #startup-list .service-slider .item .bottom h4,
  #w-t-services .service-slider .item .bottom h4 {
    margin-bottom: 7px;
  }
  #startup #s-details .container .content h2 {
    font-size: 28px;
    margin-bottom: 18px;
    line-height: 2.5rem;
  }
  #startup #s-details .container .content p {
    font-size: 16px;
  }
  #startup #middle .row .left {
    border-width: 19px;
    width: 40%;
    margin-right: 8%;
  }
  #startup #middle .row .right ul li .text {
    font-size: 17px;
  }
  #startup #middle .row .right ul li {
    margin-bottom: 23px;
  }
  #startup #middle .row .right {
    width: 45%;
  }
  #startup .st-c .bottom .label,
  #wt-hiring .bottom .label {
    margin-top: 32px;
    padding: 15px 43px;
    font-size: 17px;
  }
  #startup .st-c .bottom ul li {
    width: 80%;
    margin-bottom: 22px !important;
  }
  #startup .st-c .bottom ul li:last-child {
    margin-bottom: 0 !important;
  }
  #startup .st-c .bottom ul {
    margin: 44px auto;
    justify-content: center;
  }
  .e-t-h2 {
    font-size: 32px;
  }
  #startup .st-c p,
  #wt-get-quote p,
  #wt-hiring p,
  #wt-refer p {
    width: 73%;
  }
  #startup .st-c {
    padding: 95px 0;
  }
  #startup #st-wd .wrapper .items .item {
    width: 41%;
    margin: 30px 30px 0 0 !important;
  }
  #startup #st-wd .wrapper .items .item:nth-child(2n) {
    margin-right: 0 !important;
  }
  #startup #st-wd .wrapper .items .item:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
  .i-c-s #spotlight .main h1 {
    margin: 27px 0 13px;
    line-height: 3.2rem;
  }
  .i-c-s #spotlight .main p {
    width: 77%;
    font-size: 18px;
  }
  .semi-spotlight #spotlight div.main {
    padding: 76px 0 90px;
  }
  .semi-spotlight #spotlight div.main p {
    width: 48%;
    font-size: 16px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    font-size: 32px;
    margin-bottom: 24px;
  }
  #i-about #ab-details .container .content p {
    font-size: 16px;
  }
  #i-about #ab-details .container .content {
    width: 54%;
  }
  #i-about #ab-details .container .img {
    width: 41%;
  }
  #i-about #ab-details .container .content p:last-child {
    margin-top: 12px;
  }
  #i-about #middle .items {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 44px;
  }
  #i-about #middle .items .item {
    width: 45%;
    margin-bottom: 30px;
  }
  #i-about #middle .items .item:first-child {
    margin-right: 30px;
  }
  #i-about #middle .items .item:last-child {
    margin-bottom: 0;
  }
  #spotlight div.main h1 {
    font-size: 40px;
  }
  #spotlight div.main h4 {
    margin: 22px 0 53px;
  }
  #spotlight div.main .button {
    padding: 18px 66px;
    font-size: 16px;
  }
  #about {
    padding: 62px 0 104px;
    height: auto;
    background-size: 52%;
  }
  #about .top h2 {
    font-size: 30px;
  }
  #about .bg-animate {
    background-size: 52%;
  }
  #about .items {
    flex-wrap: wrap;
    margin-top: 69px;
    justify-content: center;
  }
  #about .items .item {
    width: 45%;
    margin-bottom: 40px;
    padding: 7% 8%;
  }
  #about .items .item:first-child {
    margin-right: 40px;
  }
  #about .items .item:last-child {
    margin: 0 auto;
  }
  #about .top p {
    margin: 14px auto;
    width: 80%;
  }
  #about .items .item h3 {
    font-size: 21px;
    margin: 23px 0 14px;
  }
  #about .items .item a {
    margin-top: 29px;
    font-size: 15px;
  }
  #about .items .item p {
    font-size: 16px;
  }
  .common-section {
    padding: 79px 0;
  }
  .common-section .head h2 {
    font-size: 35px;
    line-height: 3.1rem;
    margin: 20px 0 37px;
  }
  .common-section .head span.top {
    font-size: 23px;
  }
  .common-section .head .button .text {
    font-size: 19px;
  }
  .common-section .head .button .round {
    height: 56px;
    width: 56px;
    top: 0;
  }
  .common-section .head .button {
    padding: 19px 65px 18px 26px;
  }
  .common-section .head .button .round i {
    font-size: 22px;
  }
  #techiespark .right h2 {
    font-size: 35px;
  }
  #techiespark .right .bottom {
    font-size: 16px;
  }
  #techiespark .right p {
    font-size: 15px;
  }
  #techiespark .left {
    width: 46%;
  }
  #techiespark .wrapper {
    justify-content: center;
  }
  #techiespark .right {
    width: 40.4%;
    transform: translateY(-2px);
  }
  #why-industry.common-section:after {
    bottom: 79px;
  }
  #techiespark .right .button {
    font-size: 14px;
    margin-top: 30px;
  }
  #industry .head h3 {
    font-size: 35px;
  }
  #industry .head .bottom {
    margin: 14px auto 0;
    font-size: 17px;
  }
  #industry .head {
    margin-bottom: 42px;
  }
  #industry .contents .content-box h5 {
    font-size: 15px;
    margin-bottom: 22px;
  }
  #missions .items {
    justify-content: center;
  }
  #missions .items .item {
    width: 47%;
  }
  #missions .items .item:first-child {
    margin-right: 30px;
  }
  #missions .items .item:last-child {
    margin-top: 30px;
  }
  #how-works .items {
    justify-content: center;
  }
  #how-works .items .item {
    width: 45%;
    margin-bottom: 40px;
    margin-right: 40px;
    padding: 7% 8%;
  }
  #how-works .items .item:nth-child(2n) {
    margin-right: 0;
  }
  #how-works .title {
    font-size: 29px;
    padding-left: 26px;
    margin-bottom: 48px;
  }
  #how-works .items .item:nth-last-child(-n + 3) {
    margin-bottom: 40px;
  }
  #how-works .items .item:last-child {
    margin-bottom: 0;
  }
  #how-works .items .item h3 {
    margin: 24px 0 19px;
  }
  #how-works .items .item p {
    font-size: 15px;
  }
  #how-works .title {
    font-size: 28px;
    padding-left: 19px;
    margin-bottom: 38px;
  }
  #techiespark-spotlight header {
    padding-top: 51px;
  }
  #techiespark-spotlight .tp-main h1 {
    font-size: 40px;
  }
  #techiespark-spotlight .tp-main {
    padding: 130px 0 197px;
  }
  #techiespark-spotlight .bottom {
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/flow.svg");
    bottom: -21px;
    height: 12vw;
  }
}
@media (max-width: 950px) {
  #web_tech_schooling div.top_section {
    margin-bottom: 50px;
  }
  #web_tech_schooling div.top_section div.right_section {
    transform: translateY(-16px);
  }
}
@media (max-width: 800px) {
  #web_tech_schooling div.top_section div.left_section {
    display: none;
  }
  #web_tech_schooling div.top_section div.right_section {
    width: 100%;
    text-align: center;
    transform: translateY(0);
  }
  #techiespark-spotlight .tp-main p {
    width: 79%;
    margin-top: 17px;
    font-size: 16px;
  }
  #after .container {
    flex-wrap: wrap;
  }
  #after .container .box {
    width: 74%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  #et-working .steps {
    margin-top: 85px;
  }
  #et-working .items .item {
    padding: 50px 33px;
  }
  #et-working .items .item h5 {
    line-height: 1.6rem;
  }
  #ed-tech #e-t-about .left p {
    font-size: 17px;
  }
  #e-t-about .e-t-h {
    margin-bottom: 18px;
  }
  #ed-tech.i-c-s #spotlight .main .bottom {
    flex-wrap: wrap;
    flex-direction: column;
  }
  #ed-tech.i-c-s #spotlight .main .bottom .item {
    width: 229px;
    font-size: 17px;
    margin-right: 0;
  }
  #ed-tech.i-c-s #spotlight .main .bottom .item:first-child {
    margin-bottom: 11px;
  }
  #w-t-products .top-box .right .bottom ul li {
    width: 100%;
    margin: 0 0 25px 0 !important;
  }
  #w-t-products .top-box .right .bottom ul li:last-child {
    margin-bottom: 0 !important;
  }
  #w-t-products .top-box .right p {
    font-size: 16px;
  }
  #w-t-products .top-box .right .title {
    margin-bottom: 19px;
  }
  #w-t-products .top-box .left .title {
    font-size: 23px;
  }
  #startup .st-c,
  #wt-get-quote,
  #wt-hiring,
  #wt-refer {
    padding: 82px 0;
  }
  #w-t-about .head p {
    width: 88%;
    font-size: 16px;
  }
  #techcapital-events {
    padding: 83px 0 133px;
  }
  #techcapital-events .head {
    margin-bottom: 46px;
  }
  #startup-list .item,
  #w-t-services .item {
    padding: 0 20px;
  }
  #startup-list .slick-list,
  #w-t-services .slick-list {
    margin: 0 -20px !important;
  }
  #startup #middle {
    padding: 74px 0;
  }
  #startup .st-c p,
  #wt-get-quote p,
  #wt-hiring p,
  #wt-refer p {
    width: 77%;
    font-size: 16px;
  }
  .e-t-h2 {
    font-size: 33px;
  }
  #startup .st-c {
    padding: 82px 0;
  }
  #startup #st-wd .items .item {
    padding: 50px 30px;
  }
  .i-c-s #spotlight .main {
    padding: 106px 46px;
  }
  #startup #st-wd .wrapper .items .item {
    width: 46%;
  }
  #startup #st-wd {
    padding: 64px 0;
  }
  .i-c-s #spotlight .main .bottom .item {
    width: 175px;
    font-size: 18px;
  }
  .i-c-s #spotlight .main .top {
    padding: 10px 16px;
    font-size: 16px;
  }
  .i-c-s #spotlight .main p {
    width: 74%;
    font-size: 16px;
  }
  .i-c-s #spotlight .main h1 {
    line-height: 3rem;
    font-size: 35px;
  }
  #industry .contents {
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    padding: 60px 50px;
  }
  #industry .contents .content-box {
    width: 100%;
    box-shadow: unset;
    padding: unset;
  }
  #industry .contents .content-box:first-child {
    margin-bottom: 50px;
  }
  #industry .contents {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  footer .box .bottom {
    flex-wrap: wrap;
    width: 74%;
    line-height: 1.8rem;
  }
  footer .box .bottom a:nth-of-type(4) {
    border-right: unset;
  }
  #web_tech_schooling div.bottom_section div.cards {
    width: 100%;
  }
  #web_tech_schooling {
    padding: 50px 0 0;
  }
  #web_tech_schooling section.wrapper {
    background: 0 0;
  }
  #missions .items .item {
    width: 49%;
  }
  #missions .items .item:nth-child(2) {
    margin-right: 2%;
    margin-top: 30px;
  }
  #missions .items .item:first-child {
    margin: 0 15px;
  }
  #i-contact #offices .row .lists .item .bottom address {
    font-size: 16px;
    padding-bottom: 16px;
  }
  #i-contact #offices .row .lists .item .top h4 {
    font-size: 19px;
  }
  #i-contact #offices .row .lists .item .top {
    margin-bottom: 8px;
  }
  #i-contact #offices .row .lists .item:nth-child(2n) {
    border-right: unset;
  }
  #i-contact #offices .row .lists .item:last-child {
    margin-top: 32px;
  }
  #i-contact #offices .row .lists .item {
    width: 47%;
    padding: unset;
  }
  #i-contact #form .row .left .top input {
    padding-top: 42px;
  }
  #et-working .steps .tab .bottom .list {
    padding: 30px 38px;
  }
  #support.common-section {
    margin-top: 82px;
  }
  .uppercase {
    font-size: 27px;
  }
  #wt-projects.projects-single .item-container .content-card {
    width: 100%;
    margin-bottom: 25px;
  }
  #e-tp .container .box.right {
    padding: 58px 49px;
  }
  #e-tp .container .box.left h2 {
    font-size: 26px;
  }
  #e-tp .container .box.left p {
    width: 81%;
    font-size: 15px;
    margin-top: 14px;
  }
  .tp-com {
    padding: 73px 0 91px;
  }
  .tp-com h2 {
    font-size: 37px;
  }
  .tp-com .top {
    margin: 28px 0 44px;
  }
  .tp-com .top .round {
    height: 50px;
    width: 140px;
    margin-right: 18px;
    font-size: 13px;
    border-radius: 27px;
  }
  #tech-edu.tp-com .bottom .container,
  #tech-events.tp-com .bottom .container,
  .tp-com .bottom .container {
    width: 100%;
    flex-wrap: wrap;
  }
  #tech-edu.tp-com .bottom .container .box,
  #tech-events.tp-com .bottom .container .box,
  .tp-com .bottom .container .box {
    padding: 60px 39px;
    width: 52%;
    margin: 0 auto 21px;
  }
  #s-acce::after {
    height: 81%;
  }
  #innovation .head h2 {
    font-size: 28px;
  }
  #innovation .head {
    margin-bottom: 17px;
  }
  #menu .close-icon {
    left: 81px;
    top: 72px;
  }
  #get-started {
    padding: 30px 0 87px;
  }
  #languages {
    padding: 82px 0 25px;
  }
  #languages .head h2 {
    line-height: 2.9rem;
    font-size: 32px;
  }
  #languages .slick-list {
    margin: 0 38px !important;
  }
  #languages .item {
    padding: 0 13px;
  }
  #languages img {
    width: 47px;
  }
  #et-working .steps .tab .bottom .boxes .box {
    width: 31.33%;
    margin-right: 3%;
    margin-top: 3% !important;
  }
  #et-working .steps .tab .bottom .boxes .box:nth-child(4n),
  #et-working .steps .tab .bottom .boxes .box:nth-child(5n) {
    margin-right: 3%;
  }
  #motive .box {
    padding: 64px 56px;
  }
  #motive .box p {
    font-size: 16px;
  }
  #motive .box h2 {
    font-size: 29px;
    margin: 12px 0 19px;
  }
  #ed-tech #expertise .items .item {
    width: 40%;
    margin: 20px 20px 0 0;
  }
  #ed-tech #expertise .items .item:nth-child(2) {
    margin: unset;
  }
  #ed-tech #expertise .items .item:nth-child(2n) {
    margin-right: 0;
  }
  #ed-tech #expertise .items .item:nth-child(-n + 2) {
    margin-top: 0;
  }
  #ed-tech #e-t-about {
    padding: 60px 0;
  }
  #e-t-about .e-t-h {
    margin-bottom: 10px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide img,
  #wt-projects.projects-single .item-container .content-card img {
    width: 38%;
  }
  #startup #s-details .container .content p:last-child {
    margin-top: -8px;
  }
  #startup #s-details .container .content {
    width: 100%;
  }
  #startup #s-details .container .img {
    width: 49%;
    margin: 0 auto 20px;
  }
  #startup #s-details .container {
    padding: 47px 45px;
  }
  #startup #s-details .container .content h2 {
    font-size: 26px;
  }
  #startup #middle .row .right ul li .icon {
    height: 20px;
    width: 20px;
    font-size: 10px;
    margin-right: 20px;
  }
  #startup #middle .row .right {
    width: 92%;
  }
  #startup #middle .row .right ul {
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
    padding: 60px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  #startup #middle .row .right ul li {
    width: 90%;
    margin-bottom: 30px !important;
  }
  #startup #middle .row .right ul li:last-child {
    margin-bottom: 0 !important;
  }
  #startup #middle .head {
    display: block;
  }
  #startup #middle .row .left {
    display: none;
  }
  .i-c-s #spotlight .main h1 br {
    display: none;
  }
  #i-about #ab-details .container .img {
    display: none;
  }
  #i-about #ab-details .container .content {
    width: 100%;
  }
  #i-about #ab-details {
    padding: 57px 0 45px;
  }
  #i-about #ab-details.bottom {
    padding: 56px 0 73px;
  }
  .semi-spotlight #spotlight div.main p {
    width: 55%;
  }
  #i-about #middle .items .item {
    width: 47%;
    margin-bottom: 22px;
  }
  #i-about #middle .items .item:first-child {
    margin-right: 22px;
  }
  .e-t-h2 {
    font-size: 32px;
    line-height: 2.9rem;
  }
  #i-about #bottom h2::before {
    top: -24px;
    left: -50px;
    font-size: 79px;
  }
  #i-about #bottom h2::after {
    height: 8px;
  }
  #i-about #bottom {
    padding: 124px 0 300px;
  }
  #spotlight header h1.logo {
    width: 8.7%;
  }
  #spotlight header a.learn,
  #techiespark-spotlight header .learn {
    font-size: 17px;
  }
  .web-menu-icon span.line {
    height: 1px;
    width: 24px;
  }
  #spotlight div.main h1,
  #techiespark-spotlight .tp-main h1 {
    font-size: 36px;
  }
  #spotlight div.main h4 {
    font-size: 18px;
  }
  #spotlight div.main div.keys .round {
    font-size: 15px;
  }
  #about {
    padding: 80px 0;
  }
  #about .items .item p {
    font-size: 15px;
  }
  #about .items .item {
    margin-bottom: 44px;
    padding: 7% 5%;
  }
  #about .top h2 {
    font-size: 30px;
    line-height: 2.9rem;
  }
  #about .top p {
    font-size: 16px;
    width: 80%;
  }
  #about .items {
    margin-top: 59px;
  }
  #about .items .item .icon {
    height: 61px;
    width: 61px;
  }
  #about .items .item h3 {
    margin: 24px 0 19px;
  }
  #about .items .item a {
    font-size: 13px;
  }
  .common-section {
    padding: 70px 0;
  }
  .common-section .head h2 {
    font-size: 33px;
    line-height: 3.3rem;
  }
  .common-section .head span.top {
    font-size: 21px;
  }
  #techiespark .right h2 {
    font-size: 30px;
  }
  #techiespark .left {
    width: 50%;
    margin-right: 2%;
  }
  #techiespark .right .bottom {
    font-size: 15px;
  }
  #techiespark .right .bottom {
    margin: 10px 0 20px;
  }
  /* footer .box img {
        width: 10%;
    } */
  #techiespark-spotlight .bottom {
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/web/flow.svg");
    bottom: 0;
  }
}
@media (max-width: 680px) {
  #web_tech_schooling div.top_section div.right_section h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  #web_tech_schooling div.bottom_section div.cards {
    margin-bottom: 20px;
  }
  #et-working .wrapper .steps .tab .bottom .boxes .box {
    width: 48%;
    margin-right: 2% !important;
    margin-top: 2% !important;
  }
  #et-working .wrapper .steps .tab .bottom .boxes .box:nth-child(2n) {
    margin-right: 0 !important;
  }
  #et-working .wrapper .steps .tab .bottom .boxes .box:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
  #et-working .steps .tab .bottom {
    margin-top: 13px;
  }
  #et-working .steps .tab .top {
    padding: 18px 42px;
  }
  #et-working .steps .tab .top .left .number,
  #et-working .steps .tab .top .left p {
    font-size: 19px;
  }
  #et-working .steps .tab .top .right::before {
    font-size: 33px;
  }
  #et-working .steps .tab {
    margin-bottom: 15px;
  }
  #et-working .items .item {
    width: 47%;
    margin: 18px 18px 0 0 !important;
  }
  #et-working .items .item:nth-child(-n + 3) {
    margin: unset !important;
    margin: 18px 18px 0 0 !important;
  }
  #et-working .items .item:last-child,
  #et-working .items .item:nth-child(2n) {
    margin-right: 0 !important;
  }
  #ed-tech #e-t-about .left p {
    font-size: 16px;
  }
  #ed-tech #expertise {
    padding: 62px 0;
  }
  #ed-tech #expertise .head {
    width: 80%;
    margin: 0 auto 40px;
  }
  #ed-tech #expertise .items {
    width: 100%;
  }
  #ed-tech #why-ed-tech .row {
    margin-top: 27px;
  }
  #ed-tech #why-ed-tech {
    padding: 55px 0;
  }
  #wt-projects {
    padding: 71px 0 116px;
  }
  #w-t-products .slider .card .deatils {
    padding: 19px 0;
  }
  #wt-projects .slider .head {
    margin-bottom: 29px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide,
  #wt-projects.projects-single .item-container .content-card {
    padding: 50px 58px;
  }
  #w-t-products .top-box {
    padding: 66px 0 212px;
  }
  #w-t-products .top-box h2 {
    margin-bottom: 31px;
  }
  #w-t-products .top-box .left .desc {
    font-size: 15px;
    letter-spacing: 0.01rem;
  }
  #w-t-products .top-box .right {
    padding: 37px 20px 0 20px;
  }
  #w-t-process {
    padding: 72px 0;
  }
  #w-t-process .head {
    margin-bottom: 32px;
  }
  #w-t-process .container .item:nth-child(-n + 2) {
    margin-top: 25px !important;
  }
  #w-t-process .container .item {
    width: 92%;
    margin: 25px auto 0 !important;
  }
  #w-t-process .container .item:first-child {
    margin-top: 0 !important;
  }
  #w-t-highlights {
    padding: 61px 0;
  }
  #w-t-highlights .row .right h2 {
    margin-bottom: 30px;
  }
  #w-t-highlights .row .right ul li {
    width: 100%;
    margin-bottom: 14px;
  }
  #w-t-about {
    padding: 65px 0;
  }
  #w-t-about .head h2 {
    margin-bottom: 13px;
  }
  #w-t-about .head {
    margin-bottom: 32px;
  }
  #w-t-about .main img {
    width: 72%;
    transform: translateX(28%);
  }
  #techcapital-events .slider .button {
    padding: 13px 41px;
    font-size: 15px;
  }
  #startup-list .item,
  #w-t-services .item {
    padding: 0 13px;
  }
  #startup-list .slick-list,
  #w-t-services .slick-list {
    margin: 0 -13px !important;
  }
  #startup #s-details {
    padding: 80px 0;
  }
  #i-about #middle .items .item {
    width: 75%;
  }
  #i-about #middle .items .item:first-child {
    margin-right: 0;
  }
  .semi-spotlight #spotlight div.main p {
    width: 67%;
  }
  .common-section {
    padding: 60px 0;
  }
  .common-section .head h2 {
    font-size: 33px;
    line-height: 3.2rem;
    margin: 16px 0 45px;
  }
  .common-section .head span.top {
    font-size: 21px;
  }
  .common-section .head .button .text {
    font-size: 17px;
  }
  .common-section .head .button {
    animation: unset;
  }
  #techiespark .left .column {
    width: 44%;
  }
  #industry .head h3 {
    font-size: 33px;
  }
}
@media (max-width: 640px) {
  footer .box .bottom a {
    border-right: 0px !important;
  }
  #missions .items .item:first-child {
    margin: unset;
  }
  #missions .items .item:nth-child(2) {
    margin-bottom: 0;
    margin-right: 0;
  }
  #i-contact #form .row .left .top input {
    width: 100%;
  }
  #menu .container .item::after {
    display: none;
  }
  #et-working .steps .tab .bottom .list .item .text {
    font-size: 15px;
  }
  #et-working .steps .tab .bottom .list .item .round {
    height: 6px;
    width: 6px;
  }
  .uppercase {
    font-size: 23px;
  }
  #e-tp .container {
    flex-wrap: wrap;
  }
  #e-tp .container .box {
    width: 84%;
  }
  #e-tp .container .box.left h2 {
    font-size: 29px;
  }
  #e-tp .container .box.left p {
    width: 75%;
    margin-top: 14px;
  }
  #e-tp .container .box.right {
    background: unset;
    padding: 38px 49px;
  }
  #e-tp .container .box.right .video-container {
    margin-bottom: 30px;
  }
  #tech-events {
    padding-bottom: 31px;
  }
  #tech-edu::after,
  #tech-events::after {
    height: 60%;
  }
  #tech-edu.tp-com .top .round:nth-last-child(-n + 2),
  #tech-events.tp-com .top .round:nth-last-child(-n + 2) {
    margin-bottom: 15px;
  }
  .tp-com {
    margin: 33px auto 46px;
  }
  .tp-com h2 {
    font-size: 34px;
  }
  .tp-com .top {
    margin: 50px 0 60px;
    flex-wrap: wrap;
    width: 74%;
    margin: 47px auto;
  }
  .tp-com .top .round {
    margin-bottom: 15px;
    margin-right: 15px;
  }
  .tp-com .top .round:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
  .tp-com .top .round:nth-child(2n) {
    margin-right: 0;
  }
  .tp-com .bottom .container .box .icon img {
    margin: 0 auto 15px;
  }
  #tech-edu.tp-com .bottom .container .box,
  #tech-events.tp-com .bottom .container .box,
  .tp-com .bottom .container .box {
    padding: 47px 39px;
    width: 70%;
  }
  #innovation .video-container .overlay img {
    width: 15%;
  }
  #innovation .head {
    margin-bottom: 27px;
  }
  #innovation .head h2 {
    font-size: 29px;
    line-height: 2.7rem;
    width: 80%;
    margin: 0 auto;
  }
  #menu .close-icon {
    left: 69px;
    top: 64px;
  }
  #i-contact #offices .row .lists .item {
    width: 100%;
    padding: 25px 21px;
  }
  #i-contact #offices .row .lists .item:last-child {
    margin-top: 0;
  }
  #i-contact #offices .row .lists .item .top {
    align-items: center;
    margin-bottom: 14px;
  }
  #i-contact #offices .row .lists .item .top h4 {
    font-size: 19px;
  }
  #i-contact #offices {
    padding: 36px 48px;
  }
  #i-contact #form .row {
    padding: 66px 0;
    text-align: center;
  }
  #i-contact #form .row .left h2 {
    text-align: center;
  }
  #i-contact #form .row .left .news-letter .text {
    font-size: 17px;
  }
  #i-contact #form .row .left .news-letter .box {
    height: 24px;
    width: 24px;
    margin-right: 14px;
  }
  #i-contact #form .row .left .note {
    font-size: 16px;
    text-align: left;
  }
  #i-contact #form .row .left .top input {
    padding: 43px 0 8px;
    font-size: 16px;
  }
  #i-contact #form .row .left .top input:first-child {
    padding-top: 38px;
  }
  #i-contact #form .row .left .button {
    margin-top: 43px;
  }
  #i-contact #form .row .left .news-letter {
    margin-bottom: 30px;
  }
  #learn {
    padding: 70px 0 52px;
  }
  #learn .head {
    margin-bottom: 52px;
  }
  #learn .swiper-container {
    padding-bottom: 79px;
  }
  #after .head h2 {
    line-height: 2.9rem;
    font-size: 28px;
  }
  #get-started .box {
    padding: 70px 24px;
  }
  #get-started .box .button {
    margin-top: 34px;
    padding: 16px 29px;
    font-size: 16px;
  }
  #get-started .box h4 {
    font-size: 32px;
  }
  #languages {
    padding: 82px 0 39px;
  }
  #languages .head {
    margin-bottom: 38px;
  }
  #languages .slick-list {
    margin: 0 12px !important;
  }
  #languages .item {
    padding: 0 10px;
  }
  #motive .box .top {
    font-size: 20px;
  }
  #motive .box h2 {
    font-size: 25px;
    margin: 14px 0 17px;
    line-height: 2.4rem;
  }
  #et-working .steps {
    margin-top: 68px;
  }
  #et-working .wrapper .items .item {
    width: 68%;
    margin: 0 0 15px 0 !important;
  }
  #et-working .wrapper .items .item:last-child {
    margin-bottom: 0;
  }
  #ed-tech.i-c-s #spotlight .main .bottom .item {
    width: 220px;
    font-size: 16px;
  }
  #ed-tech.i-c-s #spotlight .main .bottom .item:first-child {
    margin-bottom: 9px;
  }
  #wt-refer p {
    margin: 22px auto 33px;
  }
  #wt-get-quote .bottom ul li,
  #wt-hiring .bottom ul li {
    width: 100%;
  }
  #wt-get-quote h2,
  #wt-hiring h2 {
    margin: 24px 0 15px;
  }
  #w-t-process .container .item {
    padding: 43px 46px;
  }
  #w-t-process .container .item p {
    font-size: 15px;
  }
  #w-t-highlights {
    padding: 64px 0;
  }
  #w-t-highlights .row .right h2 {
    margin-bottom: 25px;
  }
  #w-t-about .head p {
    width: 90%;
    font-size: 15px;
  }
  #w-t-about {
    padding: 61px 0;
  }
  #startup-list .item,
  #w-t-services .item {
    padding: 0 11px;
  }
  #startup-list .slick-list,
  #w-t-services .slick-list {
    margin: 0 -11px !important;
  }
  #startup .st-c p,
  #wt-get-quote p,
  #wt-hiring p,
  #wt-refer p {
    width: 82%;
  }
  #startup .st-c .bottom ul li {
    width: 90%;
  }
  #startup #st-wd .wrapper .items .item {
    width: 47%;
    margin: 23px 23px 0 0 !important;
  }
  .i-c-s #spotlight .main .bottom .item {
    width: 150px;
    font-size: 17px;
  }
  .i-c-s #spotlight .main h1 {
    font-size: 30px;
    margin: 18px 0 9px;
  }
  .i-c-s #spotlight .main p {
    width: 87%;
    margin-bottom: 34px;
  }
  #spotlight div.main {
    padding: 90px 0 133px;
  }
  #spotlight div.main h1,
  #techiespark-spotlight .tp-main h1 {
    font-size: 35px;
  }
  #spotlight div.main h4 {
    font-size: 17px;
    margin: 20px 0 44px;
  }
  #spotlight div.main div.keys .round:last-child {
    margin: 0 auto;
  }
  #spotlight div.main div.keys {
    display: unset;
  }
  #spotlight div.main div.keys .top {
    margin-right: 0;
  }
  #spotlight div.main div.keys .bottom {
    margin-top: -7px;
  }
  #spotlight div.main div.keys .round {
    height: 135px;
    width: 135px;
    font-size: 15px;
    margin-right: 17px;
  }
  #spotlight div.main .button {
    padding: 18px 53px;
    margin-top: 36px;
    font-size: 14px;
  }
  #spotlight header h1.logo {
    width: 9.7%;
  }
  #about .top {
    width: 100%;
  }
  #about .top h2 {
    font-size: 29px;
    line-height: 2.4rem;
  }
  #about .top p {
    width: 89%;
  }
  #about .items .item:first-child {
    margin-right: 0;
  }
  #about .items .item {
    margin-bottom: 28px;
    padding: 46px 37px;
    width: 70%;
  }
  #about .items .item h3 {
    font-size: 19px;
    line-height: 2rem;
    margin: 20px 0 13px;
  }
  #about .items .item a {
    font-size: 13px;
    margin-top: 23px;
    background: #2196f3;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 11px 27px;
    border-radius: 30px;
    font-size: 15px;
    color: #fff !important;
    text-transform: unset;
  }
  #about .items .item:first-child a {
    background: linear-gradient(#3cde74, #14abed);
    -webkit-background-clip: unset;
    -webkit-text-fill-color: #fff;
  }
  #about .items .item:nth-child(2) a {
    background: linear-gradient(#37cdfc, #4069fa);
    -webkit-background-clip: unset;
    -webkit-text-fill-color: #fff;
  }
  #about .items .item:nth-child(3) a {
    background: linear-gradient(#f25d74, #f78f4a);
    -webkit-background-clip: unset;
    -webkit-text-fill-color: #fff;
  }
  .common-section {
    padding: 60px 0;
  }
  .common-section .head h2 {
    font-size: 28px;
    line-height: 2.9rem;
    margin: 16px 0 45px;
  }
  .common-section .head span.top {
    font-size: 19px;
  }
  #techiespark .right {
    width: 100%;
    transform: translateY(0);
    margin-top: 60px;
  }
  #techiespark .left {
    width: 100%;
    margin-right: 0;
  }
  #why-industry.common-section:after {
    bottom: 191px;
    width: 896px;
  }
  #missions .items .item p {
    font-size: 15px;
  }
  #missions .items .item {
    padding: 41px 26px 38px;
  }
  #how-works .items .item {
    margin-bottom: 28px;
    padding: 46px 37px;
    width: 70%;
    margin-right: 0;
  }
  #how-works .items .item:first-child {
    margin-right: 0;
  }
  #how-works .title {
    text-align: center;
    padding-left: 0;
  }
  #how-works .items .item h3 {
    font-size: 19px;
    line-height: 2rem;
    margin: 20px 0 13px;
  }
  #techiespark-spotlight .tp-main h4 {
    font-size: 17px;
  }
  #missions .items .item {
    width: 94%;
    box-shadow: 0 8px 60px 0 rgba(209, 223, 255, 0.11),
      0 12px 90px 0 rgba(141, 147, 160, 0.11);
    margin-bottom: 25px;
    border-radius: 7px;
    padding: 52px 30px 44px;
  }
  #missions .items .item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 580px) {
  #languages .slick-list {
    margin: 0 !important;
  }
  #after {
    padding: 67px 0;
  }
  #after .container .box {
    width: 92%;
    margin-bottom: 16px;
  }
  #after .head h2 {
    line-height: 2.8rem;
    width: 80%;
    margin: 0 auto;
  }
  #after .head {
    margin-bottom: 34px;
  }
  #motive {
    padding: 27px 0;
  }
  #et-working .steps .tab .top {
    padding: 15px 37px;
  }
  #et-working .steps .tab .top .left .number,
  #et-working .steps .tab .top .left p {
    font-size: 18px;
  }
  #et-working .steps .tab .top .right::before {
    font-size: 32px;
  }
  #et-working .steps .tab {
    margin-bottom: 11px;
  }
  #et-working {
    padding: 69px 0;
  }
  #et-working .wrapper .items .item {
    width: 72%;
  }
  #ed-tech #expertise {
    padding: 57px 0;
  }
  #ed-tech #expertise .items .item {
    width: 44%;
  }
  #ed-tech #expertise .head {
    margin: 0 auto 24px;
  }
  #w-t-products .slider .card .top img {
    width: 33%;
  }
  #w-t-products .top-box .container {
    padding: 51px 33px;
  }
  #w-t-products .top-box .right {
    padding: 36px 15px 0 15px;
  }
  #w-t-products .top-box .left {
    padding-bottom: 32px;
  }
  #w-t-products .top-box .right p {
    font-size: 15px;
  }
  #w-t-products .top-box .right .title {
    font-size: 20px;
    margin-bottom: 17px;
  }
  #w-t-products .top-box .left .title {
    font-size: 21px;
    margin-top: 25px;
  }
  #w-t-products .top-box .left img {
    width: 35%;
  }
  #w-t-products .top-box .right .bottom ul li .text {
    font-size: 15px;
  }
  #w-t-products .top-box .right .bottom ul li .icon {
    height: 16px;
    width: 16px;
    font-size: 9px;
    margin-right: 12px;
  }
  .slick-dots li button:before {
    font-size: 12px !important;
  }
  .slick-dots li.slick-active button:before {
    font-size: 16px !important;
  }
  #work-tech #w-t-services .slick-list {
    margin: 0 6px !important;
  }
  #w-t-about .head p {
    width: 95%;
    font-size: 15px;
  }
  #techcapital-events .slider-items .content-card {
    padding: 0 0;
  }
  #techcapital-events .slider-items .item-slide .bottom .location {
    font-size: 15px;
  }
  #techcapital-events .slider-items .item-slide .bottom {
    padding: 43px 33px;
  }
  #techcapital-events .slider-items .item-slide .bottom .date {
    margin-bottom: 7px;
  }
  #w-t-services .head {
    margin-bottom: 31px;
  }
  #startup-list .head,
  #w-t-services .head {
    margin-bottom: 61px;
  }
  #startup-list .slick-dots,
  #w-t-services .slick-dots {
    top: -56px;
  }
  #startup-list,
  #w-t-services {
    padding: 76px 0;
  }
  #startup-list .item,
  #w-t-services .item {
    padding: 0 9px;
  }
  #startup-list .slick-list,
  #w-t-services .slick-list {
    margin: 0 -9px !important;
  }
  #startup #s-details .container .img {
    width: 82%;
    margin: 0 auto 23px;
  }
  #startup #s-details.bottom .container .img {
    margin: 23px auto 0;
  }
  #startup #middle .head {
    margin-bottom: 22px;
  }
  #startup #middle .row .right ul {
    padding: 56px 50px;
  }
  #startup #middle {
    padding: 56px 0 65px;
  }
  .i-c-s #spotlight .main {
    padding: 87px 24px;
  }
  .i-c-s #spotlight .main h1 {
    font-size: 28px;
    line-height: 2.4rem;
  }
  #startup #st-wd {
    padding: 69px 0 74px;
  }
  #startup #st-wd .wrapper .items .item {
    width: 76%;
    margin: unset !important;
    margin-bottom: 28px !important;
  }
  #startup #st-wd .wrapper .items .item:last-child {
    margin-bottom: 0 !important;
  }
  #i-about #middle {
    text-align: center;
    padding: 36px 0;
  }
  .e-t-h2 {
    font-size: 28px;
  }
  #i-about #bottom h2::before {
    left: -47px;
    font-size: 72px;
  }
  #i-about #bottom h2::after {
    height: 7px;
  }
  #spotlight header a.learn {
    font-size: 17px;
  }
  #spotlight div.main h1 {
    width: 90%;
    font-size: 32px;
    line-height: 37px;
  }
  #spotlight div.main h4 {
    margin: 21px 0 40px;
  }
  #spotlight div.main div.keys .round {
    font-size: 12.5px;
    margin: unset;
    height: 120px;
    width: 120px;
  }
  #spotlight div.main div.keys .round:last-child {
    margin: 0 auto;
  }
  #spotlight div.main div.keys .top span.round:last-child {
    margin-left: 10px;
    margin-right: 0;
  }
  #spotlight div.main div.keys .top {
    justify-content: center;
  }
  #spotlight div.main .button {
    font-size: 14px;
    width: 170px;
    padding-left: 0;
    padding-right: 0;
    height: 51px;
    text-align: center;
    margin-top: 30px;
  }
  #spotlight div.main div.keys {
    flex-wrap: wrap;
    width: 273px;
    justify-content: space-between;
    align-items: center;
  }
  #about {
    padding: 68px 0 72px;
  }
  .common-section .head h2 {
    font-size: 26px;
    margin: 16px 0 45px;
  }
  .common-section .head span.top {
    font-size: 18px;
  }
  #how-works .items .item {
    padding: 52px 39px;
    width: 77%;
  }
}
@media (max-width: 540px) {
  #get-started .box {
    padding: 68px 24px;
  }
  #get-started .box h4 {
    font-size: 30px;
  }
  #get-started .box .button {
    margin-top: 30px;
    padding: 13px 25px;
    font-size: 15px;
  }
  #languages .head h2 {
    line-height: 2.4rem;
  }
  #ed-tech #et-working .wrapper .steps .tab .bottom .boxes .box {
    width: 76%;
    margin: 0 auto 20px !important;
  }
  #ed-tech #et-working .wrapper .steps .tab .bottom .boxes .box:last-child {
    margin-bottom: 0 !important;
  }
  #startup .st-c,
  #wt-get-quote,
  #wt-hiring,
  #wt-refer {
    padding: 73px 0;
  }
  #startup .st-c .bottom ul {
    margin: 32px auto 40px;
  }
  #startup .st-c p,
  #wt-get-quote p,
  #wt-hiring p,
  #wt-refer p {
    width: 90%;
  }
  #startup .st-c h2 {
    margin-bottom: 15px;
  }
  #startup .st-c {
    padding: 73px 0;
  }
  #startup .st-c .bottom .label,
  #wt-hiring .bottom .label,
  #wt-projects .slider .botton,
  #wt-refer .button {
    font-size: 16px;
  }
  .e-t-h2 {
    line-height: 2.4rem;
  }
  #startup .st-c .bottom ul li {
    width: 95%;
    padding: 18px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    font-size: 29px;
  }
  #missions .items .item {
    width: 82%;
    margin-bottom: 30px;
  }
  #missions .items .item:last-child {
    margin-bottom: 0;
  }
  #missions .items .item:first-child {
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  #w-t-process .container .item h4 {
    margin: 14px 0 8px;
    font-size: 20px;
    line-height: 1.7rem;
  }
  #w-t-highlights {
    padding: 50px 0;
  }
  #w-t-services .head {
    margin-bottom: 24px;
  }
  #techcapital-events .slider-items .item-slide .bottom {
    padding: 31px 20px;
  }
  #startup #s-details .container {
    padding: 39px 37px;
  }
  #startup #s-details {
    padding: 64px 0;
  }
  #w-t-products {
    padding-bottom: 18px;
  }
  #w-t-products .top-box .right p {
    text-align: justify;
  }
  #learn .swiper-container .swiper-pagination {
    display: none !important;
  }
  #learn {
    padding: 59px 0 7px;
  }
  #startup-list,
  #w-t-services {
    padding: 51px 0;
  }
  #missions .items .item:last-child,
  #missions .items .item:nth-child(2) {
    margin-top: 24px;
  }
  #w-t-products .top-box .right .bottom ul li .icon {
    height: unset;
    width: unset;
    font-size: 11px;
    background: unset;
    color: #52af50;
  }
  #et-working .steps .tab .bottom {
    padding: 0 36px 36px;
  }
  #et-working .steps .tab .bottom .list {
    padding: 22px 25px;
  }
  #et-working .steps .tab .bottom .list .item {
    margin-bottom: 15px;
  }
  #et-working .steps .tab .bottom .list .item .text {
    font-size: 14px;
  }
  #support.common-section {
    margin-top: 66px;
  }
  .uppercase {
    font-size: 17px;
  }
  #wt-hiring .bottom ul {
    margin: 31px 0 !important;
  }
  #ed-tech #why-ed-tech .slick-dots {
    bottom: -36px !important;
  }
  #wt-projects.projects-single .item-container .content-card {
    margin-bottom: 22px;
  }
  #e-tp .container .box.left p {
    width: 90%;
  }
  #e-tp .container .box.right {
    padding: 35px 17px;
  }
  #e-tp .container .box.right .video-container {
    margin-bottom: 23px;
  }
  .tp-com {
    padding: 60px 0 19px;
  }
  .tp-com .top {
    margin: 35px auto 40px;
  }
  .tp-com h2 {
    font-size: 30px;
  }
  .tp-com .bottom .container .box p {
    font-size: 16px;
  }
  #tech-edu.tp-com .bottom .container .box,
  #tech-events.tp-com .bottom .container .box,
  .tp-com .bottom .container .box {
    padding: 40px 35px;
    width: 79%;
  }
  .tp-com .top .round {
    height: 48px;
    width: 133px;
    font-size: 12px;
  }
  #s-acce::after {
    height: 87%;
  }
  #innovation {
    padding: 70px 0;
  }
  #innovation .head h2 {
    font-size: 24px;
    line-height: 2.2rem;
    width: 74%;
  }
  #menu .close-icon {
    left: 36px;
    top: 36px;
  }
  #menu .container .item .number {
    margin-right: 41px;
  }
  #menu .container .item .menu,
  #menu .container .item .number {
    font-size: 21px;
  }
  #menu .container .item {
    margin-bottom: 33px;
  }
  #techiespark .left .column {
    width: 43%;
  }
  #i-contact #form .row .left {
    width: 89%;
    margin: 0 auto;
  }
  #i-contact #offices .row .lists .item {
    padding: 15px 8px;
  }
  #i-contact #offices .row .lists .item .top {
    margin-bottom: 10px;
  }
  #i-contact #form .row .left .top input {
    padding: 35px 0 7px;
    font-size: 15px;
  }
  #learn .swiper-pagination-bullet {
    margin: 0 8px !important;
  }
  #learn .swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 15px !important;
    width: 15px !important;
  }
  #learn .head {
    margin-bottom: 26px;
  }
  #learn .swiper-container {
    padding-bottom: 71px;
  }
  #get-started .box h4 {
    font-size: 24px;
    line-height: 2.1rem;
  }
  #get-started .box {
    padding: 59px 24px;
  }
  #get-started .box .button {
    margin-top: 22px;
  }
  #et-working .head {
    margin-bottom: 28px;
  }
  #languages .head h2 {
    font-size: 27px;
    line-height: 2.2rem;
  }
  #after .container .box {
    padding: 41px;
  }
  #after .container .box ul {
    margin-top: 20px;
  }
  #after .container .box ul li {
    color: #6a6a6a;
    font-size: 16px;
    margin-bottom: 15px;
  }
  #after .container .box ul li .icon {
    font-size: 16px;
    margin-right: 13px;
  }
  #after .container .box ul li .text {
    font-size: 15px;
    line-height: 1.4rem;
  }
  #after .container .box .heading {
    font-size: 18px;
  }
  #after .head h2 {
    line-height: 2.4rem;
    margin: 0 auto;
    font-size: 23px;
    width: 85%;
  }
  #et-working {
    padding: 49px 0 38px;
  }
  #ed-tech #et-working .wrapper .steps .tab .bottom .boxes .box {
    width: 90%;
    margin: 0 auto 17px !important;
  }
  #motive .box {
    padding: 58px 44px;
    text-align: center;
  }
  #motive .box .left {
    text-align: center;
  }
  #motive .box .top {
    font-size: 18px;
  }
  #motive .box p {
    font-size: 15px;
    text-align: justify;
  }
  #motive .box h2 {
    font-size: 22px;
    margin: 11px 0 16px;
    line-height: 2.3rem;
  }
  #et-working .steps .tab {
    margin-bottom: 8px;
  }
  #et-working .steps .tab .top .left .number,
  #et-working .steps .tab .top .left p {
    font-size: 15px;
  }
  #et-working .steps .tab .top .right::before {
    font-size: 28px;
  }
  #et-working .steps .tab .top {
    padding: 14px 24px;
  }
  #et-working .steps .tab .top .right::before {
    font-size: 30px;
  }
  #et-working .steps .tab .top {
    padding: 14px 33px;
  }
  #et-working .wrapper .items .item {
    width: 90%;
    padding: 46px 39px;
  }
  #et-working .items .item .icon {
    height: 60px;
    width: 60px;
    font-size: 32px;
  }
  #et-working .items .item h5 {
    margin: 17px 0 14px;
    line-height: 1.6rem;
  }
  #ed-tech #expertise .head {
    width: 88%;
  }
  #ed-tech #expertise .items .item {
    width: 70%;
    margin: unset !important;
    margin-bottom: 18px !important;
  }
  #ed-tech #expertise .items .item:last-child {
    margin-bottom: 0 40;
  }
  #ed-tech #expertise .items .item img {
    width: 39%;
  }
  #ed-tech #why-ed-tech .row .item h4 {
    font-size: 18px;
    letter-spacing: 0.01rem;
  }
  #e-t-about .e-t-h {
    margin-bottom: 7px;
  }
  #ed-tech #e-t-about .wrapper {
    flex-wrap: wrap;
    width: 85%;
  }
  #ed-tech #e-t-about .left {
    order: 2;
  }
  #ed-tech #e-t-about .right {
    width: 100%;
    display: block;
    order: 1;
    margin-bottom: 40px;
  }
  #ed-tech #e-t-about .right img {
    width: 60%;
    margin: 0 auto;
  }
  #ed-tech #e-t-about .left p {
    font-size: 15px;
    line-height: 1.7rem;
    text-align: justify;
  }
  #ed-tech.i-c-s #spotlight .main .bottom .item {
    width: 215px;
    font-size: 15px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide .type,
  #wt-projects .slider .slider-items .content-card .item-slide p {
    font-size: 15px;
  }
  #wt-projects .slider .botton {
    margin-top: 84px;
  }
  #wt-projects .slider .slider-items .content-card .item-slide,
  #wt-projects.projects-single .item-container .content-card {
    padding: 45px 23px;
  }
  #wt-projects .slider .head {
    margin-bottom: 24px;
  }
  #w-t-products .top-box {
    padding: 59px 0 194px;
  }
  #w-t-products .top-box h2 {
    margin-bottom: 24px;
  }
  #w-t-products .top-box .left .desc {
    font-size: 14px;
  }
  #w-t-products .top-box .right .title {
    margin-bottom: 15px;
  }
  #w-t-products .top-box .left {
    padding-bottom: 29px;
  }
  #w-t-products .top-box .left .title {
    font-size: 19px;
    margin: 21px 0 9px;
    letter-spacing: 0.01rem;
  }
  #w-t-products .top-box .right {
    padding: 31px 0 0 0;
  }
  #w-t-products .top-box .right .bottom ul li {
    margin-bottom: 11px !important;
  }
  #wt-refer p {
    margin: 20px auto 31px;
  }
  #wt-get-quote .bottom .label {
    font-size: 12px;
  }
  #wt-hiring .top-button,
  #wt-projects .slider .botton,
  #wt-refer .button {
    font-size: 15px;
  }
  #startup .st-c,
  #startup .st-c #wt-hiring,
  #wt-get-quote,
  #wt-hiring {
    padding: 64px 0;
  }
  #wt-get-quote .bottom ul,
  #wt-hiring .bottom ul {
    width: 100%;
  }
  #wt-get-quote .bottom ul li,
  #wt-hiring .bottom ul li {
    font-size: 15px;
  }
  #w-t-process .container .item .icon {
    height: 60px;
    width: 60px;
    font-size: 30px;
  }
  #w-t-about {
    padding: 59px 0;
  }
  #w-t-about .head h2 {
    margin-bottom: 12px;
  }
  #w-t-about .head {
    margin-bottom: 28px;
  }
  #w-t-about .head p {
    width: 97%;
  }
  #w-t-about .main img {
    width: 76%;
    transform: translateX(16%);
    border-radius: 5px;
  }
  #w-t-services .service-slider .item .bottom {
    padding-top: 25px;
  }
  #techcapital-events {
    padding: 49px 0 111px;
  }
  #techcapital-events .head {
    margin-bottom: 30px;
  }
  #techcapital-events .slider .button {
    padding: 13px 38px;
    font-size: 14px;
  }
  #techcapital-events .slider-items .item-slide .bottom h4 {
    font-size: 21px;
    margin: 5px 0 5px;
  }
  #techcapital-events .slider-items .item-slide .bottom p {
    font-size: 15px;
    text-align: justify;
  }
  #techcapital-events .slider-items .item-slide .bottom .date,
  #techcapital-events .slider-items .item-slide .bottom .location {
    font-size: 13px;
  }
  #startup #s-details .container .content h2 {
    font-size: 23px;
    line-height: 2rem;
  }
  #startup #s-details .container .content p {
    font-size: 15px;
    text-align: justify;
  }
  #startup #middle .row .right ul li .icon {
    height: 17px;
    width: 17px;
    font-size: 8px;
  }
  #startup #middle .row .right ul li .text {
    font-size: 16px;
    letter-spacing: 0.02rem;
    line-height: 1.4rem;
  }
  #startup #middle .row .right ul {
    padding: 48px 43px;
  }
  #startup #middle .row .right ul li {
    margin-bottom: 21px !important;
    width: 100%;
  }
  #startup .st-c .bottom ul {
    width: 100%;
  }
  #startup .st-c .bottom .label,
  #wt-get-quote .top-button,
  #wt-hiring .bottom .label {
    font-size: 15px;
    letter-spacing: 0.01rem;
  }
  #startup .st-c .bottom ul li {
    padding: 18px 0;
  }
  #startup .st-c .bottom ul li,
  #startup .st-c p,
  #wt-get-quote p,
  #wt-hiring p,
  #wt-refer p {
    width: 100%;
    font-size: 15px;
  }
  #startup #st-wd {
    padding: 60px 0 74px;
  }
  #startup #st-wd .head {
    margin-bottom: 36px;
  }
  #startup #st-wd .wrapper .items .item {
    width: 90%;
  }
  .i-c-s #spotlight .main .top {
    padding: 9px 16px;
    font-size: 14px;
    letter-spacing: 0.01rem;
  }
  .i-c-s #spotlight .main .bottom .item {
    font-size: 16px;
    letter-spacing: 0.02rem;
    padding: 11px 20px;
  }
  .i-c-s #spotlight .main p {
    font-size: 15px;
  }
  #i-about #ab-details {
    padding: 54px 0 24px;
  }
  #i-about #bottom {
    padding: 97px 0 289px;
  }
  .e-t-h2 {
    font-size: 27px;
  }
  #i-about #bottom h2::before {
    left: -44px;
    font-size: 66px;
  }
  #i-about #bottom h2::after {
    width: 140px;
    bottom: -23px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    margin-bottom: 15px;
  }
  #i-about #middle .items {
    margin-top: 24px;
  }
  #i-about #ab-details .container .content p,
  #i-about #middle .items .item p {
    font-size: 15px;
    text-align: justify;
    margin-bottom: 7px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    font-size: 26px;
    line-height: 2.4rem;
  }
  #i-about #middle .items .item .top {
    font-size: 18px;
    padding: 17px 10px;
  }
  #i-about #ab-details.bottom {
    padding: 45px 0 70px;
  }
  .semi-spotlight #spotlight div.main p {
    width: 78%;
    font-size: 15px;
  }
  .semi-spotlight #spotlight div.main h2 {
    margin-bottom: 4px;
  }
  #i-about #middle .items .item {
    width: 95%;
    margin-bottom: 30px;
  }
  #root {
    overflow: hidden;
  }
  #spotlight {
    min-height: unset;
    background: linear-gradient(#7053c1, #c255ac);
  }
  .web-menu-icon span.line {
    width: 19px;
  }
  #spotlight div.main {
    min-height: unset;
    height: unset;
    padding: 74px 0 102px;
  }
  #spotlight div.main::before {
    background: #ffffff91;
  }
  #spotlight div.main div.keys .round {
    border-width: 1px;
  }
  #spotlight div.main h1 {
    font-size: 28px;
  }
  #spotlight div.main h4 {
    font-size: 16px;
    margin: 15px 0 40px;
  }
  #spotlight header h1.logo {
    width: 12.7%;
  }
  #spotlight header .wrapper {
    padding: 16px 0;
  }
  #spotlight div.main div.keys .top span.round:last-child {
    margin: unset;
  }
  #spotlight div.main div.keys .round {
    font-size: 13px;
    margin-right: 10px;
  }
  #spotlight div.main .button {
    width: 169px;
    margin-top: 40px;
    letter-spacing: 0.1rem;
    font-size: 13px;
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #spotlight header a.learn,
  #techiespark-spotlight header .learn {
    font-size: 14px;
  }
  #spotlight div.main div.keys {
    height: unset;
    width: unset;
  }
  #about {
    padding: 40px 0 68px;
    height: auto;
  }
  #about .top {
    width: 100%;
  }
  #about .items {
    margin-top: 43px;
    border-radius: 8px;
    background: #fff;
  }
  #about .items .item {
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.07),
      0 12px 22px 0 rgba(103, 151, 255, 0.08);
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    padding: 43px 0;
    border-radius: 7px;
    position: relative;
    background: #fff;
  }
  #about .items .item::after {
    position: absolute;
    width: 30%;
    height: 1px;
    background: #000;
  }
  #about .items .item .icon {
    margin: 0 auto;
    background: #fff;
  }
  #about .items .item br {
    display: none;
  }
  #about .items .item h3 {
    width: 75%;
    margin: 20px auto 6px;
    line-height: 1.9rem;
    font-size: 20px;
  }
  #about .top p {
    font-size: 15px;
    letter-spacing: 0.02rem;
  }
  #about .top h2 {
    font-size: 26px;
    line-height: 2.4rem;
  }
  #about .items .item p {
    font-size: 16px;
    width: 84%;
    margin: 0 auto;
  }
  #about .items .item a {
    margin-top: 25px;
    letter-spacing: 1px;
  }
  .web-menu-icon {
    height: 18px;
  }
  .web-menu-icon span.line {
    height: 1px;
  }
  #initiative {
    background: -webkit-radial-gradient(
      top left,
      circle,
      #4a42ec 0,
      #521d91 100%
    );
    background: radial-gradient(circle at top left, #4a42ec 0, #521d91 100%);
    background-color: #521d91;
    min-height: unset;
    width: 93%;
    box-shadow: 0 48px 48px -32px rgba(23, 16, 159, 0.2),
      0 96px 96px -64px rgba(23, 16, 159, 0.4);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    position: relative;
  }
  #initiative::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: #cfb5f1f0;
    right: -100px;
    bottom: -47px;
  }
  .common-section {
    padding: 49px 0;
    min-height: unset;
  }
  .common-section .head span.top {
    font-size: 15px;
  }
  .common-section .head h2 {
    font-size: 21px;
    letter-spacing: 0.02rem;
    line-height: 2rem;
    margin: 19px 0 32px;
  }
  .common-section .head .button {
    padding: unset;
    width: 169px;
    height: 53px;
    display: flex;
    align-items: center;
    padding-left: 28px;
    margin: 0 auto;
    animation: unset;
  }
  .common-section .head .button .text {
    font-size: 16px;
    letter-spacing: 0.02rem;
  }
  .common-section .head .button .round {
    width: 53px;
    top: 0;
    height: 53px;
  }
  .common-section .head .button .round i {
    font-size: 23px;
    -webkit-text-stroke-width: 1.5px;
  }
  #techiespark {
    height: unset;
  }
  #techiespark .left {
    width: 100%;
  }
  #techiespark .right {
    width: 100%;
    transform: unset;
    margin-top: 37px;
  }
  #techiespark .right h2 {
    font-size: 26px;
  }
  #techiespark .right p {
    font-size: 15px;
  }
  #techiespark .right .button {
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 27px;
  }
  #why-industry.common-section:after {
    right: -32px;
    bottom: 152px;
  }
  #industry::after {
    width: 100%;
    background: unset;
  }
  #industry {
    min-height: unset;
    padding-top: 3px;
  }
  #industry .head h3 {
    font-size: 31px;
    letter-spacing: 0.06rem;
    font-family: product_sansbold;
    color: #343433;
  }
  #industry .head .bottom {
    width: 90%;
    margin: 10px auto 0;
    font-size: 17px;
    line-height: 1.64rem;
    color: #343433;
  }
  #industry .contents {
    width: 100%;
    background: #fff;
    border-radius: 7px;
    padding: 57px 41px;
  }
  #industry .contents .content-box .items .item {
    width: 100%;
    margin-bottom: 15px;
    align-items: baseline;
  }
  #industry .contents .content-box .items .item:nth-last-child(-n + 2) {
    margin-bottom: 15px;
  }
  #industry .contents .content-box .items .item:last-child {
    margin-bottom: 0;
  }
  #industry .contents .content-box h4 {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.02rem;
    color: #343434;
  }
  #industry .contents .content-box h5 {
    font-size: 15px;
    margin-bottom: 20px;
    color: #3fa5fb;
    line-height: 1.6rem;
  }
  #industry .contents .content-box .items .item .line {
    background: #343434;
    width: 5px;
    margin-right: 25px;
    height: 5px;
    border-radius: 50%;
  }
  #industry .head {
    text-align: center;
    margin-bottom: 34px;
  }
  #industry .contents .content-box:first-child {
    margin-bottom: 49px;
  }
  #industry .button {
    animation: unset;
    width: 53px;
    height: 53px;
    line-height: unset;
    display: none;
    align-items: center;
    justify-content: center;
    background: #484848;
    transform: translate(-50%);
    right: 50%;
    left: 50%;
    bottom: -26px;
  }
  #industry .button i {
    font-size: 23px;
    -webkit-text-stroke-width: 1.5px;
    color: #fff;
  }
  #industry svg {
    position: absolute;
    bottom: -864px;
    width: 49%;
    transform: rotate(-90deg);
    z-index: -1;
    left: -26px;
  }
  #support.common-section {
    background: linear-gradient(237deg, #46b3ff 0, #0752fe 100%);
    border-radius: 7px;
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
      0 12px 90px 0 rgba(103, 151, 255, 0.11);
  }
  #support.common-section .head .button {
    background: unset;
  }
  #support.common-section .head .button .round {
    background: #fff;
    position: absolute;
    z-index: 10;
    transform: translate(-50%);
    right: 50%;
    left: 50%;
  }
  #support.common-section .head .button .round i {
    color: #308cfa;
  }
  #missions {
    background: #fff;
    margin-bottom: 134px;
  }
  #missions.mission-animate::after {
    background: #fff;
  }
  #missions .items {
    flex-wrap: wrap;
    margin-top: -119px;
  }
  #missions .items .item {
    width: 99%;
    box-shadow: 0 8px 60px 0 rgba(209, 223, 255, 0.11),
      0 12px 90px 0 rgba(141, 147, 160, 0.11);
    margin-bottom: 25px;
    border-radius: 7px;
    padding: 52px 30px 44px;
  }
  #missions .items .item:last-child {
    margin-bottom: 0;
  }
  #missions .items .item p {
    letter-spacing: 0.04rem;
  }
  #missions .items .item:first-child img {
    /* width: 45%; */
  }
  #missions .items .item img {
    width: 60%;
    margin: 0 auto 17px;
  }
  #missions .items .item .button {
    margin-top: 22px;
    box-shadow: 0 1px 35px -13px rgba(61, 61, 61, 0.6);
  }
  footer .box {
    width: 100%;
  }
  /* footer .box img {
        width: 14%;
    } */
  footer .box .address {
    font-size: 15px;
    margin: 17px 0;
  }
  footer .box .social .item {
    width: 40px;
    height: 40px;
    border-radius: 15px;
  }
  footer .box .bottom a {
    font-size: 15px;
  }
  footer .box .bottom {
    margin-top: 20px;
    /* width: 248px; */
  }
  #how-works .items .item {
    padding: 57px 0;
    width: 100%;
  }
  #how-works .items .item br {
    display: none;
  }
  #how-works .items .item h3 {
    width: 75%;
    margin: 20px auto 6px;
    line-height: 1.9rem;
    font-size: 20px;
  }
  #how-works .items .item p {
    font-size: 16px;
    width: 84%;
    margin: 0 auto;
  }
  #how-works .title {
    font-size: 26px;
    margin-bottom: -8px;
  }
  #techiespark-spotlight .tp-main h4 {
    margin-top: 15px;
    font-size: 15px;
    width: 65%;
    margin: 12px auto 0;
    line-height: 1.4rem;
  }
  #techiespark-spotlight .bottom {
    background-size: 685px 48px;
    bottom: -10px;
  }
  #techiespark-spotlight header {
    padding-top: 37px;
  }
}
@media (max-width: 440px) {
  #web_tech_schooling div.top_section div.right_section p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  #web_tech_schooling div.bottom_section div.cards div.content_container p {
    font-size: 14px;
  }
  .tp-com .top {
    width: 100%;
  }
  #w-t-products .top-box .right p {
    font-size: 14px;
  }
  #w-t-products .top-box .right .bottom ul li .text {
    font-size: 14px;
  }
  #et-working .steps .tab .top {
    padding: 14px 20px;
  }
  #et-working .steps .tab .top .left .number {
    margin-right: 10px;
  }
  #et-working .steps .tab .top .right::before {
    font-size: 28px;
  }
  #e-tp .container .box.right .video-container .overlay img {
    width: 17%;
  }
}
@media (max-width: 400px) {
  #techcapital-events .head {
    margin-bottom: 19px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    font-size: 23px;
  }
  #missions .items .item {
    width: 100%;
  }
  #et-working .steps .tab .bottom .list .item .round {
    height: 5px;
    width: 5px;
    margin-right: 11px;
  }
  #et-working .steps .tab .bottom .list {
    padding: 17px 19px;
  }
  #et-working .steps .tab .bottom .list .item {
    margin-bottom: 11px;
  }
  #e-tp .container .box.right {
    padding: 29px 10px;
  }
  #e-tp .container .box.left h2 {
    font-size: 24px;
  }
  #e-tp .container .box.left h2 {
    font-size: 26px;
  }
  #e-tp .container .box.left p {
    width: 99%;
  }
  .tp-com {
    padding-bottom: 0;
  }
  .tp-com .bottom .container .box {
    padding: 40px 35px;
    width: 88%;
    margin-bottom: 15px;
  }
  .tp-com .top {
    width: 100%;
  }
  #innovation .head h2 {
    width: 90%;
  }
  #menu .close-icon {
    left: 36px;
    top: 36px;
  }
  #spotlight div.main div.keys .round {
    margin-right: 8px;
  }
  #spotlight div.main div.keys .bottom {
    margin-top: -6px;
  }
  #w-t-process .container .item p {
    font-size: 14px;
  }
  #i-contact #form .row .left .top input {
    padding: 29px 0 6px;
    font-size: 15px;
  }
  #i-contact #form .row .left .note {
    font-size: 14px;
  }
  #i-contact #form .row {
    padding: 60px 0;
  }
  #i-contact #form .row .left .top input:first-child {
    padding-top: 33px;
  }
  #i-contact #offices {
    padding: 26px 30px;
  }
  #i-contact #offices .row .lists .item .top {
    margin-bottom: 7px;
  }
  #i-contact #offices .row .lists .item .top h4 {
    font-size: 17px;
    line-height: 1.7rem;
  }
  #i-contact #offices .row .lists .item .top .icon {
    font-size: 21px;
  }
  #i-contact #offices .row .lists .item .bottom address {
    font-size: 15px;
  }
  #i-contact #form .row .left .button {
    padding: 12px 39px;
    font-size: 15px;
    margin-top: 23px;
  }
  #i-contact #form .row .left .news-letter .text {
    font-size: 15px;
    line-height: 1.3rem;
    text-align: left;
  }
  #learn {
    padding: 62px 0 53px;
  }
  #learn .swiper-container {
    padding-bottom: 65px;
  }
  #learn .head {
    margin-bottom: 30px;
  }
  #get-started {
    padding: 8px 0 76px;
  }
  #languages {
    padding: 69px 0 40px;
  }
  #languages .head h2 {
    font-size: 25px;
  }
  #after .head {
    margin-bottom: 29px;
  }
  #after .container .box {
    padding: 36px 30px;
  }
  #ed-tech #et-working .wrapper .steps .tab .bottom .boxes .box {
    width: 100%;
    margin: 0 auto 11px !important;
  }
  #et-working .steps .tab .bottom {
    padding: 0 24px 36px;
  }
  #motive .box {
    padding: 49px 34px;
  }
  #motive .box h2 {
    font-size: 22px;
    margin: 11px 0 15px;
    line-height: 2.1rem;
  }
  #et-working .steps .tab .top {
    padding: 12px 19px;
  }
  #et-working .steps .tab .top .right::before {
    font-size: 26px;
  }
  #et-working .steps .tab .top .left .number,
  #et-working .steps .tab .top .left p {
    font-size: 15px;
  }
  #et-working .wrapper .items .item {
    width: 94%;
  }
  #et-working .items .item p {
    font-size: 15px;
  }
  #ed-tech #expertise .items .item {
    width: 80%;
    padding: 33px 0;
  }
  #ed-tech #why-ed-tech .row .item {
    padding: 12px 22px;
  }
  #wt-projects {
    padding: 56px 0 101px;
  }
  #w-t-products .top-box {
    padding: 53px 0 187px;
  }
  #w-t-products .top-box h2 {
    margin-bottom: 22px;
  }
  #w-t-products .top-box .container {
    padding: 46px 27px;
  }
  #w-t-products .top-box .left {
    padding-bottom: 22px;
  }
  #w-t-products .top-box .left img {
    width: 42%;
  }
  #w-t-products .top-box .right {
    padding: 28px 2px 0 2px;
  }
  #w-t-products .top-box .right .title {
    margin-bottom: 14px;
    font-size: 19px;
  }
  #wt-hiring .top-button {
    font-size: 14px;
  }
  #wt-get-quote .bottom ul li,
  #wt-hiring .bottom ul li {
    font-size: 14px;
    padding: 10px 0;
    line-height: 1.4rem;
  }
  #w-t-process .container .item:nth-child(-n + 2) {
    margin-top: 17px !important;
  }
  #w-t-process .container .item:first-child {
    margin-top: 0 !important;
  }
  #w-t-process .container .item {
    width: 100%;
    margin: 17px auto 0 !important;
    padding: 38px 28px;
  }
  #w-t-process {
    padding: 58px 0;
  }
  #w-t-highlights .row .right {
    width: 98%;
  }
  #w-t-highlights .row .right ul li {
    margin-bottom: 9px;
  }
  #w-t-highlights .row .right ul li .text {
    font-size: 15px;
  }
  #w-t-services .service-slider .item .bottom h4 {
    font-size: 20px;
  }
  #techcapital-events .slider-items .item-slide .bottom h4 {
    font-size: 19px;
    line-height: 1.9rem;
    margin: 11px 0 11px;
  }
  #techcapital-events .slider-items .item-slide .bottom {
    padding: 35px 24px;
  }
  #startup #s-details .container {
    padding: 47px 36px;
  }
  #startup #middle .row .right ul li .icon {
    height: 7px;
    width: 7px;
    font-size: 0;
  }
  #startup #middle .row .right ul {
    padding: 43px 36px;
  }
  #startup #middle .row .right ul li .text {
    font-size: 15px;
  }
  #startup #middle .row .right ul li {
    margin-bottom: 17px !important;
  }
  #startup #st-wd .head {
    margin-bottom: 29px;
  }
  #startup .st-c .bottom .label,
  #wt-get-quote .top-button,
  #wt-hiring .bottom .label,
  #wt-projects .slider .botton,
  #wt-refer .button {
    font-size: 14px;
    padding: 14px 30px;
  }
  #startup .st-c .bottom ul {
    margin: 28px auto 32px;
  }
  #startup .st-c {
    padding: 64px 0;
  }
  #startup .st-c .bottom ul li {
    padding: 15px 0;
    font-size: 14px;
    line-height: 1.4rem;
    margin-bottom: 18px !important;
  }
  #startup #st-wd .items .item {
    padding: 41px 32px;
  }
  #startup #st-wd .items .item p {
    font-size: 15px;
  }
  .i-c-s #spotlight .main {
    padding: 106px 24px;
  }
  .i-c-s #spotlight .main .top {
    padding: 9px 14px;
    font-size: 12px;
  }
  .i-c-s #spotlight .main h1 {
    margin: 13px 0 8px;
    font-size: 27px;
  }
  .i-c-s #spotlight .main p {
    width: 96%;
    margin-bottom: 23px;
  }
  #startup #st-wd .wrapper .items .item {
    width: 95%;
  }
  #i-about #middle .items .item .top {
    font-size: 16px;
    padding: 13px 10px;
    margin-bottom: 15px;
  }
  #i-about #ab-details {
    padding: 46px 0 16px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    margin-bottom: 10px;
  }
  #i-about #middle .items {
    margin-top: 19px;
  }
  #i-about #bottom h2::after {
    width: 135px;
    bottom: -21px;
    height: 6px;
    right: 83px;
  }
  #i-about #ab-details.bottom {
    padding: 26px 0 58px;
  }
  .e-t-h2 {
    font-size: 26px;
  }
  #i-about #bottom h2::before {
    left: -29px;
    font-size: 57px;
  }
  #i-about #middle .items .item {
    width: 100%;
    margin-bottom: 25px;
  }
  #techiespark-spotlight header {
    padding-top: 32px;
  }
  .web-menu-icon {
    height: 16px;
  }
  .web-menu-icon span.line {
    width: 17px;
  }
  .web-menu-icon span.line:nth-child(2) {
    width: 15px;
  }
  .web-menu-icon span.line:last-child {
    width: 19px;
  }
  #spotlight header a.learn {
    font-size: 13px;
  }
  #spotlight div.main {
    padding: 73px 0 86px;
  }
  #spotlight div.main h1 {
    font-size: 27px;
  }
  #spotlight div.main h4 {
    font-size: 15px;
    margin: 16px 0 31px;
  }
  #spotlight div.main div.keys .round {
    font-size: 12px;
    height: 100px;
    width: 100px;
  }
  #spotlight div.main .button {
    width: 145px;
    height: 44px;
    font-size: 12px;
  }
  #about .top p {
    margin: 14px auto;
  }
  .common-section .head .button {
    width: 145px;
    height: 44px;
    padding-left: 22px;
  }
  .common-section .head .button .round {
    width: 44px;
    height: 44px;
  }
  .common-section .head .button .round i {
    font-size: 19px;
  }
  .common-section .head .button .text {
    font-size: 15px;
  }
  #techiespark .right .button {
    font-size: 13px;
  }
  #industry .contents .content-box h5 {
    font-size: 14px;
  }
  #industry .contents {
    padding: 50px 40px;
  }
  #industry .contents .content-box h4 {
    font-size: 15px;
  }
  #industry .head h3 {
    font-size: 29px;
  }
  #industry .head .bottom {
    width: 80%;
    margin: 7px auto 0;
    font-size: 15px;
  }
  #missions .items .item .button {
    width: 44px;
    height: 44px;
  }
  #missions .items .item {
    padding: 50px 28px 44px;
  }
  #missions .items {
    margin-top: -121px;
  }
  #missions .items .item p {
    font-size: 15px;
  }
  #missions .items .item .button .icon {
    font-size: 21px;
  }
  footer .box .address {
    font-size: 14px;
    margin: 17px 0;
  }
  footer .box .bottom a {
    font-size: 13px;
  }
  footer .box .bottom {
    flex-wrap: wrap;
    width: 74%;
    line-height: 1.8rem;
    padding: 0;
  }
  footer .box .social .item {
    width: 35px;
    height: 35px;
    border-radius: 12px;
    margin-right: 11px;
  }
  footer .box .social .item svg {
    width: 44%;
  }
  #techiespark-spotlight .tp-main h4 {
    font-size: 14px;
    width: 60%;
  }
  #techiespark-spotlight .tp-main h1 {
    font-size: 34px;
  }
}
@media (max-width: 360px) {
  .semi-spotlight #spotlight div.main p {
    font-size: 14px;
  }
  #web_tech_schooling div.bottom_section div.cards div.content_container h3 {
    font-size: 16px;
  }
  #web_tech_schooling div.top_section div.right_section a {
    padding: 12px 31px;
  }
  #i-contact #form .row {
    padding: 49px 0;
  }
  #i-contact #form .row .left .button {
    padding: 10px 33px;
    margin-top: 22px;
  }
  #i-contact #form .row .left {
    width: 95%;
  }
  #i-contact #offices .row .lists .item .bottom address {
    font-size: 15px;
  }
  #after .head h2 {
    line-height: 2.3rem;
    width: 100%;
  }
  #motive .box {
    padding: 49px 24px;
  }
  #et-working .steps .tab .bottom {
    padding: 0 23px 36px;
  }
  #et-working .steps .tab .top .left {
    align-items: baseline;
    text-align: left;
  }
  #et-working .steps .tab .top .left p {
    width: 90%;
  }
  #et-working .wrapper .items .item {
    padding: 43px 39px;
    width: 95%;
    margin-bottom: 12px !important;
  }
  #ed-tech.i-c-s #spotlight .main .top {
    font-size: 13px;
  }
  #w-t-about .head p {
    font-size: 14px;
  }
  #techcapital-events {
    padding: 41px 0 104px;
  }
  #startup .st-c .bottom ul li {
    padding: 11px 0;
  }
  .i-c-s #spotlight .main {
    padding: 85px 24px;
  }
  .i-c-s #spotlight .main .bottom .item {
    font-size: 15px;
    width: 140px;
  }
  #i-about #ab-details .container .content h2,
  #i-about #middle h2 {
    font-size: 21px;
  }
  .i-c-s #spotlight .main .top {
    font-size: 11px;
  }
  #startup #st-wd .wrapper .items .item {
    width: 100%;
    margin-bottom: 23px !important;
  }
  #startup #st-wd {
    padding: 56px 0 71px;
  }
  .e-t-h2 {
    font-size: 25px;
  }
  #i-about #bottom h2::before {
    left: -18px;
    font-size: 37px;
    top: -18px;
  }
  #techiespark-spotlight .tp-main h1 {
    font-size: 33px;
  }
  #techiespark-spotlight .tp-main h4 {
    width: 67%;
  }
  .web-menu-icon {
    height: 15px;
  }
  .web-menu-icon span.line {
    width: 16px;
  }
  .web-menu-icon span.line:nth-child(2) {
    width: 14px;
  }
  .web-menu-icon span.line:last-child {
    width: 18px;
  }
  #spotlight div.main {
    padding: 73px 0 62px;
  }
  #spotlight div.main h1 {
    font-size: 23px;
    line-height: 1.8rem;
  }
  #spotlight div.main h4 {
    font-size: 14px;
    margin: 16px 0 29px;
  }
  #spotlight div.main div.keys .round {
    font-size: 11px;
    height: 90px;
    width: 90px;
  }
  #spotlight div.main .button {
    width: 130px;
    height: 42px;
    font-size: 11px;
  }
  #about {
    padding: 54px 0 69px;
  }
  #about .items .item p {
    font-size: 15px;
  }
  #about .items .item h3 {
    width: 72%;
    font-size: 20px;
  }
  #about .top h2 {
    font-size: 25px;
    line-height: 2.4rem;
  }
  #initiative {
    width: 94%;
  }
  .common-section .head h2 {
    line-height: 1.7rem;
    font-size: 19px;
    margin: 9px 0 26px;
  }
  .common-section .head span.top {
    font-size: 15px;
  }
  .common-section .head .button .text {
    font-size: 14px;
  }
  .common-section .head .button {
    height: 42px;
    width: 130px;
    padding-left: 18px;
  }
  .common-section .head .button .round {
    width: 42px;
    height: 42px;
  }
  #why-industry {
    width: 94%;
  }
  #industry .contents .content-box .items .item .line {
    margin-right: 20px;
  }
  #industry .head {
    margin-bottom: 30px;
  }
  #industry .contents .content-box .items .item,
  #industry .contents .content-box .items .item:nth-last-child(-n + 2) {
    margin-bottom: 15px;
  }
  #industry .contents .content-box:first-child {
    margin-bottom: 19px;
  }
  .common-section .head h2 {
    line-height: 2.1rem;
    font-size: 18px;
  }
  .common-section .head span.top {
    font-size: 14px;
  }
  #industry .contents {
    padding: 50px 33px;
  }
  #industry .contents .content-box .items .item .line {
    width: 5px;
    height: 5px;
  }
  #industry .head h3 {
    font-size: 27px;
  }
  #missions .items .item .button {
    width: 42px;
    height: 42px;
  }
  footer .box .address {
    line-height: 1.4rem;
    width: 80%;
    font-size: 13px;
  }
  footer .box .bottom {
    /* width: 205px; */
  }
  footer .box .social .item {
    width: 30px;
    height: 30px;
    margin-right: 9px;
  }
  #about .items .item h3,
  #how-works .items .item h3 {
    width: 72%;
    font-size: 20px;
  }
  #about .items .item p,
  #how-works .items .item p {
    font-size: 15px;
  }
}
@media (max-width: 320px) {
  #how-works .items .item h3 {
    width: 78%;
    font-size: 19px;
  }
  #how-works .title {
    font-size: 23px;
  }
  #et-working .wrapper .items .item {
    width: 97%;
  }
}
.redo-anim {
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
div.react-calendar.calender {
  position: absolute;
  top: 55px;
  right: 0;
  background: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 6px;
  color: #9e9e9e;
  z-index: 1;
}
